import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbDateStruct, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { ControllerService } from 'src/app/controller.service';
import { DeadlineToday } from 'src/app/gis/model/poidetail/deadlinetoday';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';
import { OpeningEventService } from 'src/app/gis/services/opening-event.service';
import { fromNgbDateStructToDate, isSameDay } from 'src/app/gis/util/dateutil';
import { CounterState } from 'src/app/gis/model/poidetail/counterstate';
import { Period } from 'src/app/gis/model/poidetail/period';
import { AccordionTrackerComponent } from '../accordion-tracker.component';
import { DeadlinesProductAtDate } from '@app/app/gis/model/poidetail/deadlinesproductatdate';
import { TagManagerService } from '@app/app/common/tag.service';

@Component({
  selector: 'app-deadlines',
  templateUrl: './deadlines.component.html',
  styleUrls: ['./deadlines.component.scss'],
})
export class DeadlinesComponent extends AccordionTrackerComponent implements OnInit, OnDestroy {
  public showDeadlinesToday: boolean;
  public showDeadlinesAtDate: boolean;

  public infoText: string;
  public deadlinesAtTitle: string;
  public deadlinesProductAtDate: DeadlinesProductAtDate[] = [];

  private intervalSubscription: Subscription;
  private _poi: PoiDetail;

  constructor(
    private currentDateTimeService: CurrentDateTimeService,
    private openingEventService: OpeningEventService,
    private controllerService: ControllerService,
    protected readonly translateExtendedService: TranslateExtendedService,
    protected readonly tagManagerService: TagManagerService
  ) {
    super(translateExtendedService, tagManagerService);
  }

  public get poi() {
    return this._poi;
  }

  @Input()
  public set poi(poi: PoiDetail) {
    this._poi = poi;
    this.initPoiData();
  }

  public trackPanelChange(change: NgbPanelChangeEvent) {
    super.internalTrackPanelChange('tab-annahmeschluss', change.nextState, 'detail.hours.deadlines');
  }

  ngOnDestroy(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.intervalSubscription = this.controllerService
      .getUpdateDataInterval()
      .subscribe(() => this.updateFields(this.currentDateTimeService.getCurrentDateTime()));
  }

  private initPoiData() {
    this.showDeadlinesAtDate = false;
    this.showDeadlinesToday = true;

    if (this.controllerService.getSearchParameters().value?.date) {
      this.initOpenAtSpecificDate(this.controllerService.getSearchParameters().value.date);
    } else {
      this.updateFields(this.currentDateTimeService.getCurrentDateTime());
    }
  }

  private initOpenAtSpecificDate(dateStruct: NgbDateStruct) {
    this.showDeadlinesAtDate = true;
    this.showDeadlinesToday = false;

    this.translateExtendedService
      .get('detail.hours.deadlineat', { date: `${dateStruct.day}.${dateStruct.month}.${dateStruct.year}` })
      .subscribe((title) => {
        this.deadlinesAtTitle = title;
      });

    const date = fromNgbDateStructToDate(dateStruct);
    this.deadlinesProductAtDate = this.openingEventService.getDeadlinesProductAtDate(this.poi.deadlinesForProduct, date);

    this.updateFields(date);
  }

  private updateFields(now: Date) {
    this.infoText = null;

    if (this.poi) {
      // deadlines
      for (const deadline of this.poi.deadlinesToday) {
        this.updateDeadline(deadline, now);
      }
      if (!this.infoText) {
        for (const counterState of this.poi.counterStates) {
          this.updateHolidayInfo(counterState, now);
        }
      }
      this.updateClosedPeriodInfo(this.poi.closedPeriods, now);
    }
  }

  private updateDeadline(deadline: DeadlineToday, now: Date) {
    // check if counter is open or closed.
    deadline.deadLineStillOk = isSameDay(now, deadline.deadLine) && now <= deadline.deadLine;
    this.openingEventService.getDeadlineEvent(now, deadline.deadLine, false).subscribe((translation) => {
      deadline.timeString = translation;
    });
  }

  private updateHolidayInfo(counterState: CounterState, now: Date) {
    if (!counterState.holidays) {
      return;
    }

    const todayHoliday = counterState.holidays.find((holiday) => isSameDay(holiday.date, now));
    if (todayHoliday) {
      this.infoText = todayHoliday.isOpen ? 'detail.hours.holidayOpenInfo' : 'detail.hours.holidayClosedInfo';
    }
  }

  private updateClosedPeriodInfo(closedPeriods: Period[], now: Date) {
    if (closedPeriods && closedPeriods.some((closedPeriod) => now >= closedPeriod.from && now <= closedPeriod.to)) {
      this.infoText = 'detail.hours.closedPeriodInfo';
    }
  }
}
