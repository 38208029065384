import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { BaseLanguageService, LANG_STORAGE_KEY } from './base-language.service';

@Injectable({
    providedIn: 'root'
  })
export class BrowserLanguageService extends BaseLanguageService {

    constructor(cookieService: CookieService) {
        super(cookieService);
    }

    public setLanguage(language: string): string {
        const sanitizedLang = this.sanitizeLanguage(language);

        if (document) {
            document.documentElement.lang = sanitizedLang;
        }

        if (window && window.localStorage) {
            window.localStorage.setItem(LANG_STORAGE_KEY, sanitizedLang);
        }

        this.saveLanguageInCookie(sanitizedLang);
        return sanitizedLang;
    }

    public getLanguage(): string {
        let language = this.getFromUrlPath(window.location.href);
        if (language) {
            return this.setLanguage(language);
        }

        language = this.getFromUrlParameter('lang', window.location.href);
        if (language) {
            return this.setLanguage(language);
        }

       language = this.getFromCookie();
        if (language) {
            return this.setLanguage(language);
        }

        language = window.localStorage.getItem(LANG_STORAGE_KEY);
        if (language) {
            return this.setLanguage(language);
        }

        language = this.getBrowserLanguage();
        language = language == null ? this.defaultLanguage : language;
        return this.setLanguage(language);
    }

    private getBrowserLanguage(): string {
        if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
            return null;
        }

        const browserKey = 'browserLanguage';
        const userLanguageKey = 'userLanguage';

        let browserLang: any =  window.navigator.languages ? window.navigator.languages[0] : null;
        browserLang = browserLang || window.navigator.language ||
                      window.navigator[browserKey] || window.navigator[userLanguageKey];

        if (browserLang.indexOf('-') !== -1) {
            browserLang = browserLang.split('-')[0];
        }

        if (browserLang.indexOf('_') !== -1) {
            browserLang = browserLang.split('_')[0];
        }

        return this.sanitizeLanguage(browserLang);
    }
}
