import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { ConfigurationService } from 'src/app/config/configuration.service';
import { ControllerService } from 'src/app/controller.service';
import { BaseLocationService } from 'src/app/gis/location/base.location.service';
import { PoiListItem } from '@app/app/gis/model/poibase';
import 'src/app/gis/model/spiderfier';
import { MapService } from 'src/app/gis/services/map.service';
import { BaseMapComponent } from '@app/app/base/components/base-map.component';
import { NGXLogger } from 'ngx-logger';
import { NeedsCacheService } from '@app/app/gis/services/needs-cache.service';
import { RoutingService } from '@app/app/common/routing.service';
import { map } from 'rxjs/operators';
import { GeocodedArea } from '@app/app/gis/model/geocodedArea';
import { GisService } from '@app/app/gis/services/gis.service';
import { TagManagerService } from '@app/app/common/tag.service';
import { fromDateToNgbDateStruct } from '@app/app/gis/util/dateutil';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends BaseMapComponent implements OnDestroy, AfterViewInit {
  @ViewChild('mapWrapper') mapWrapper: ElementRef;

  public needId: number;

  constructor( // NOSONAR
    configurationService: ConfigurationService,
    controllerService: ControllerService,
    resolver: ComponentFactoryResolver,
    injector: Injector,
    mapService: MapService,
    locationService: BaseLocationService,
    needsService: NeedsCacheService,
    logger: NGXLogger,
    @Inject(DOCUMENT) document: Document,
    protected routingService: RoutingService,
    protected gisService: GisService,
    protected readonly tagManagerService: TagManagerService
  ) {
    super(configurationService, controllerService, resolver, injector, mapService, locationService, needsService, logger, document);

    if (window && 'IntersectionObserver' in window) {
      this.mapObserver = new IntersectionObserver(
        ([entry]: IntersectionObserverEntry[]) => {
          if (!this.mapWrapper) {
            return;
          }
          this.mapWrapper.nativeElement.classList.toggle('map-mostly-visible', entry.intersectionRatio >= 0.8);
        },
        {
          threshold: 0.8,
        }
      );
    }
  }

  @HostListener('window:scroll') onScroll() {
    if (!this.mapWrapper.nativeElement) {
      return;
    }

    const isSticky = this.mapWrapper.nativeElement.getBoundingClientRect().top <= 0;
    this.mapWrapper.nativeElement.classList.toggle('is-sticky', isSticky);
  }

  protected markerClicked(item: PoiListItem) {
    if (item && item.id) {
      /* eslint-disable @typescript-eslint/naming-convention */
      this.tagManagerService.click({
        event: 'select_content',
        type: 'places_poi',
        label: item.serviceType.id,
        text: item.name.toLowerCase(),
        additional_info: 'map'
      });
      /* eslint-enable @typescript-eslint/naming-convention */
      this.controllerService.showPoi(item);
    }
  }

  protected getSecondLevelNeedId(): string {
    let secondLevelNeedId;
    const needId = this.routingService.getNeedIdParam();

    if (this.needs.length > 0) {
      const selectedNeed = this.needs.filter(x => x.id === Number(needId));
      const selectedProductNeed = this.needs.filter(n => n.secondlevelneeds.some(s => s.id === needId));

      if (selectedNeed.length > 0) {
        secondLevelNeedId = selectedNeed[0].secondlevelneeds[0].id;
      } else if (selectedProductNeed.length > 0) {
        secondLevelNeedId = needId;
      } else {
        if (needId) {
          this.logger.info(`NeedID not found: ${needId}`);
        }
        this.needsService.getDefaultSecondLevelNeedId().subscribe((defaultNeedId) => {
          secondLevelNeedId = defaultNeedId;
        });
      }
    }

    return secondLevelNeedId;
  }

  protected startFirstSearch() {
    const secondLevelNeedId = this.getSecondLevelNeedId();
    const need = this.needs.filter(n => n.secondlevelneeds.some(s => s.id === secondLevelNeedId));
    const searchParameters = this.controllerService.getDefaultSearchParams(need[0].id, secondLevelNeedId);

    const time = this.routingService.getQueryParam('time');
    if (time) {
      let date;
      if (time.toLowerCase() === 'now') {
        date = new Date();
      } else if (time.toLowerCase() === 'today16') {
        date = new Date();
        date.setHours(16);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
      } else {
        date = new Date(time);
      }

      if (!isNaN(date)) {
        searchParameters.date = fromDateToNgbDateStruct(date);
        searchParameters.time = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        searchParameters.openNow = true;
      }
    }

    const locationQuery = this.routingService.getQueryParam('preselecttext');
    if (locationQuery) {
      this.gisService
        .geocodeByNeed(need[0].id, locationQuery)
        .pipe(map((items: GeocodedArea[]) => this.controllerService.sortGeocodedAreas(locationQuery, items)))
        .subscribe(geocodedAreas => {
          if (geocodedAreas.length > 0) {
            searchParameters.location = geocodedAreas[0];
          } else {
            searchParameters.query = locationQuery;
          }
        });
    }

    this.controllerService.startSearch(searchParameters);
  }
}
