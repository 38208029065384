import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigurationService } from '../config/configuration.service';
import slugify from 'slugify';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    private readonly metaService: Meta,
    @Inject(DOCUMENT) private documentService: Document,
    private readonly configurationService: ConfigurationService,
    private readonly titleService: Title
  ) {}

  public static createSeoDetailUrl(
    baseUrl: string,
    poiId: string,
    title: string,
    language: string
  ): string {
    return `${baseUrl ? baseUrl : ''}/${language}/${poiId}/${SeoService.cleanUrlFragment(title, language)}`;
  }

  public static cleanUrlFragment(fragment: string, language: string) {
    return slugify(
      fragment
        .replace(/\//g, '-')
        .replace(/\,/g, '-')
        .replace(/\'/g, '-')
        .replace(/\./g, '-'),
      {
        replacement: '-',  // replace spaces with replacement character, defaults to `-`
        remove: /[*+~()"!:@]/g, // remove characters that match regex, defaults to `undefined`
        lower: true,      // convert to lower case, defaults to `false`
        strict: false,     // strip special characters except replacement, defaults to `false`
        locale: language,       // language code of the locale to use
        trim: true         // trim leading and trailing replacement chars, defaults to `true`
      }
    );
  }

  public addLink(type: string, url: string, language?: string): void {
    const link: HTMLLinkElement = this.documentService.createElement('link');
    link.setAttribute('rel', type);
    if (language) {
      link.setAttribute('hreflang', language);
    }
    link.setAttribute('href', url);
    this.documentService.head.appendChild(link);
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public setOgTitle(title: string) {
    this.metaService.addTag({ name: 'og:title', content: title });
  }

  public setDescription(description: string) {
    this.metaService.addTag({ name: 'description', content: description });
  }

  public setOgDescription(description: string) {
    this.metaService.addTag({ name: 'og:description', content: description });
  }

  public setLangLinks(languageUrls, currentLanguage: string) {
    languageUrls.filter((key) => key.lang === currentLanguage).forEach((key) => {
      this.addLink('canonical', key.url, key.lang);
    });
    languageUrls.filter((key) => key.lang !== currentLanguage).forEach((key) => {
      this.addLink('alternate', key.url, key.lang);
    });
  }

  public setKeywords(keywords: string) {
    this.metaService.addTag({ name: 'keywords', keywords });
  }

  public setRobots(robots?: string) {
    this.metaService.addTag({
      name: 'robots',
      content: robots
        ? robots
        : this.configurationService.getConfiguration().seoRobots,
    });
  }
}
