<div class="py-4" *ngIf="hasAnyAddress()">

  <form>
    <h3 class="font-curve-regular bold mb-4" translate>detail.addressing.generateAddress</h3>

    <div class="row">
      <div class="col-rg-6">

        <!-- Switch zwischen Pickpost und Postlagernd und MyPost24 -->
        <fieldset>
          <legend class="sr-only" translate>detail.addressing.addresstype</legend>
          
          <div class="btn-group btn-group-toggle form-group" ngbRadioGroup [(ngModel)]="at" name="addressType">
            <!-- Pickpost -->
            <label for="detail-addressing-pickpost" *ngIf="hasPickPostAddress()" class="btn btn-outline-secondary"
              ngbButtonLabel translate>
              <input id="detail-addressing-pickpost" type="radio" ngbButton value="pickpost">
              detail.addressing.pickpost
            </label>

            <!-- Postlagernd -->
            <label for="detail-addressing-postlagernd" *ngIf="hasPostlagerndAddress()" class="btn btn-outline-secondary"
              ngbButtonLabel translate>
              <input id="detail-addressing-postlagernd" type="radio" ngbButton value="postlagernd">
              detail.addressing.postlagernd
            </label>

            <!-- MyPost24 -->
            <label for="detail-addressing-mypost24" *ngIf="hasMyPost24Address()" class="btn btn-outline-secondary"
              ngbButtonLabel translate>
              <input id="detail-addressing-mypost24" type="radio" ngbButton value="mypost24">
              detail.addressing.mypost24
            </label>
          </div>
        </fieldset>

        <!-- Anrede -->
        <div class="form-group">
          <select id="anrede" class="form-control form-control-lg" required [(ngModel)]="anredeDropDownVal"
            name="anrede">
            <option selected translate value="nochoice">detail.addressing.chooseSalutation</option>
            <option value="male" translate>detail.addressing.male</option>
            <option value="female" translate>detail.addressing.female</option>
            <option value="family" translate>detail.addressing.family</option>
            <option value="nosalutation" translate>detail.addressing.noSalutation</option>
          </select>
          <label for="anrede" translate>detail.addressing.salutation</label>
        </div>

        <!-- Vor- und Nachname -->
        <div class="form-group">
          <input id="nameVorname" class="form-control form-control-lg" [(ngModel)]="nameVorname" name="nameVorname"
            placeholder="Vor- und Nachname">
          <label for="nameVorname" translate>detail.addressing.fullname</label>
        </div>

        <!-- Userid, nur bei Pickpost und MyPost24 relevant -->
        <div *ngIf='hasUserId()'>
          <div class="form-group">
            <input id="userId" name="userId" [(ngModel)]="userId" class="form-control form-control-lg"
              placeholder="Vor- und Nachname">
            <label for="userId" translate>detail.addressing.userId</label>
          </div>
          <p><a [href]="klpUrl" target="_blank" (click)="trackLinkClick($event)"><span
                [innerHTML]="'detail.addressing.wtfIsUserId' | translate"></span>&nbsp;<i
                class="pi pi-small pi-3020" aria-hidden="true"></i></a></p>
        </div>
        <hr>

      </div>
      <div class="col-rg-6">
        <span id="detail-adressing-info" class="sr-only" translate>detail.addressing.info</span>
        <div class="p-4 bg-gray-cararra" tabindex="0">

          <h4  class="font-curve-regular bold" aria-labelledby="detail-adressing-info">{{getTitleForAddressType() | translate}}</h4>
              
          <p class="font-curve-regular">

            <!-- Anrede -->
            <span class="addresspart-placeholder" *ngIf="showAnredePlaceholder()"
              translate>detail.addressing.salutation</span>
            <span *ngIf="hasAnrede()">{{getAnrede() | async}}&nbsp;</span>

            <!-- Vorname Nachname -->
            <span class="addresspart-placeholder" *ngIf="showVornameNachnamePlaceholder()"
              translate>detail.addressing.fullname</span>
            <span>{{nameVorname}}</span>

            <br>

            <!-- UserId -->
            <ng-container *ngIf="hasUserId()">
              <span *ngIf="at === 'mypost24'">{{'detail.addressing.addressPartMyPost24' | translate}}&nbsp;</span>
              <span *ngIf="at === 'pickpost'">{{'detail.addressing.addressPartPickpost' | translate}}&nbsp;</span>
              <span class="addresspart-placeholder" *ngIf="showUserIdPlaceholder()"
                translate>detail.addressing.yourUserId</span>
              <span>{{userId}}</span>
            </ng-container>
            <span *ngIf="at === 'postlagernd'">{{'detail.addressing.addressPartPostlagernd' | translate}}&nbsp;</span>
            <br>
            <span>{{address?.street}}</span>
            <br>
            <span>{{address?.zip}} {{address?.city}}</span>
          </p>
        </div>
      </div>
    </div>
  </form>
</div>