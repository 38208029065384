<div class="result-list-item">
  <button [id]="'result-' + poiListItem?.id"
    (click)="itemClicked($event)" 
    class="result-list-link text-left"
    [class.itemactive]="hoveringActive === true"
    (mouseenter)="mouseEnter()" 
    (mouseleave)="mouseLeave()"
    (focus)="focus()"
    (blur)="mouseLeave()"
    tabindex="0">

    <div class="d-flex justify-content-between">
      <address>
        <p class="bold">{{poiListItem?.name}}</p>
        <p class="bold" *ngIf="poiListItem?.additionalDescription">{{poiListItem?.additionalDescription}}</p>
        <p class="light">
          <span *ngIf="poiListItem?.street">{{poiListItem?.street}}</span>
          <span *ngIf="poiListItem?.street && (poiListItem?.zip || poiListItem?.city)">,&nbsp;</span>
          <span *ngIf="poiListItem?.zip">{{poiListItem?.zip}}</span>
          <span *ngIf="poiListItem?.zip && poiListItem?.city">&nbsp;</span>
          <span *ngIf="poiListItem?.city">{{poiListItem?.city}}</span>
        </p>
      </address>
      <div class="result-list-icon">
        <i class="pi" aria-hidden="true" [ngClass]="iconClass()"></i>
        <span *ngIf="locationServiceEnabled" class="result-list-distance">{{distanceToCurrentLocation | number:'0.1-1'}} km</span>
      </div>
    </div>

    <app-result-list-deadline [poi]="poiListItem"></app-result-list-deadline>

    <div class="toast toast-info" *ngIf="poiListItem?.hasHints === true">
      <div class="toast-message" translate>resultlistitem.hintinfo</div>
    </div>
  </button>
</div>
