import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ConfigurationService } from '@app/app/config/configuration.service';
import { ControllerService } from '@app/app/controller.service';
import { BaseLocationService } from '@app/app/gis/location/base.location.service';
import { PoiListAggregate, PoiListItem, PoiOrAggregate } from '@app/app/gis/model/poibase';
import { PoiList } from '@app/app/gis/model/poilist';
import { SearchParameters } from '@app/app/gis/model/searchparameters';
import { SimpleCoordinates } from '@app/app/gis/model/simplecoordinates';
import { MapService } from '@app/app/gis/services/map.service';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';

import { getAccuracyInPixels } from '@app/app/gis/util/coordinatesutil';

import { createLocationMarker } from '@app/app/gis/location/locationmarker';
import { BoundingBox } from '@app/app/gis/model/boundingbox';
import { GeoCodedAreaType } from '@app/app/gis/model/geocodedAreaType';
import { AngularInfoWindow } from '@app/app/gis/model/googlemaps';
import { NeedConfig } from '@app/app/gis/model/needconfig';
import { SpiderfyStatus, SpiderMarker } from '@app/app/gis/model/spiderfier';
import { NeedsCacheService } from '@app/app/gis/services/needs-cache.service';
import { isTechPlz } from '@app/app/gis/util/isTechPlz';
import { boundingBoxToLatLngBounds, toGoogleCoordinates } from '@app/app/gis/util/maphelpers';
import { AggregateInfoWindowComponent } from '@app/app/home/components/map/aggregate-info-window/aggregate-info-window.component';
import { PoiInfoWindowComponent } from '@app/app/home/components/map/poi-info-window/poi-info-window.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseMapComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('toposmapcontainer') mapElement: ElementRef;

  // If User already has the google maps scripts loaded in the page he can set this flag as true
  // this way the component will not load any script and will use any existing googlemaps script
  @Input('disable-script-loading') disableScriptLoading: boolean;
  @Input('google-maps-key') googleMapsKey: string;
  @Input('google-maps-src') googleMapsSrc: string;
  @Input('disable-fullscreen') disableFullscreen: boolean;
  @Input('disable-street-view') disableStreetView: boolean;
  @Input('disable-zoom') disableZoom: boolean;
  @Input('zoom-gesture-handling') zoomGestureHandling: 'cooperative' | 'greedy' | 'none' | 'auto';

  public map: google.maps.Map;
  public markers: Map<string, google.maps.Marker> = new Map<string, google.maps.Marker>();
  public aggregateIndex = new Map<string, google.maps.Marker>();
  public needs: NeedConfig[] = [];

  public resultsReceived$ = new Subject();

  protected searchParameters: BehaviorSubject<SearchParameters>;
  protected mapObserver: IntersectionObserver = null;
  protected destroy$ = new Subject();
  protected markerIndex = new Map<string, SpiderMarker>();
  protected spider = null;
  protected zoomLevel = 8;
  protected maxZoom = 18;

  protected boundsObservable: Observable<unknown> = null;
  protected boundsSub$: Subscription;
  private zoomSub$: Subscription;
  private needInitSub$: Subscription;

  private poiHovered: PoiOrAggregate = null;
  private currentLocationMarker: google.maps.OverlayView = null;


  constructor( // NOSONAR
    protected configurationService: ConfigurationService,
    protected controllerService: ControllerService,
    protected resolver: ComponentFactoryResolver,
    protected injector: Injector,
    protected mapService: MapService,
    protected locationService: BaseLocationService,
    protected needsService: NeedsCacheService,
    protected logger: NGXLogger,
    @Inject(DOCUMENT) protected document: Document
  ) {
    this.searchParameters = this.controllerService.getSearchParameters();

    this.controllerService
      .getHoveredPoiObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((poi: PoiOrAggregate) => this.hoverChanged(poi));

    this.controllerService
      .getZoomLevelObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((zoomLevel) => this.zoomLevelChanged(zoomLevel));

    this.controllerService
      .getSearchObservable()
      .pipe(takeUntil(this.destroy$))
      .subscribe((x) => {
        this.resultsReceived$.next(x.count);
        this.poiListChanged(x);
      });
  }

  ngOnInit(): void {
    this.zoomSub$ = this.controllerService.getZoomObservable().pipe(takeUntil(this.destroy$)).subscribe((zoom) => {
      this.map.setZoom(zoom);
    });

    this.needInitSub$ = this.needsService.getNeeds().pipe(takeUntil(this.destroy$)).subscribe((needs) => {
      this.needs = needs;
    });
  }

  ngOnDestroy(): void {
    if (this.mapObserver && this.mapElement) {
      this.mapObserver.unobserve(this.mapElement.nativeElement);
    }

    this.controllerService.resetController();

    if (this.spider) {
      this.spider.removeAllMarkers();
      this.spider = null;
    }

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit() {
    this.initializeMap();
  }

  protected getIcon(item: PoiListItem) {
    return { url: this.mapService.markerUrl(item) };
  }

  protected getActiveIcon(item: PoiListItem) {
    return { url: this.mapService.markerUrl(item, false, true) };
  }

  protected markerMouseEnter(item: PoiOrAggregate) {
    // Remove focus from current result list item if another one is hovered
    (document.activeElement as HTMLElement).blur();
    this.poiHovered = item;
    this.controllerService.hoverPoi(item);
  }

  protected markerMouseLeave() {
    this.poiHovered = null;
    this.controllerService.hoverPoi(null);
  }

  protected toggleLocation(showLocation: boolean) {
    if (showLocation) {
      // Show current location marker if location service is already enabled
      this.locationService
        .getLocation()
        .pipe(takeUntil(this.destroy$))
        .subscribe((x: SimpleCoordinates) => this.createCurrentLocationMarker(x));

      // Refresh current location marker if location service is enabled
      this.controllerService
        .getUpdateDataInterval()
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.locationService
            .isLocationServiceEnabled()
            .pipe(take(1))
            .subscribe((locationEnabled: boolean) => {
              if (locationEnabled) {
                this.locationService
                  .getLocation()
                  .pipe(takeUntil(this.destroy$))
                  .subscribe((x: SimpleCoordinates) => this.createCurrentLocationMarker(x));
              }
            });
        });
    } else {
      // Remove current location marker
      this.removeCurrentLocationMarker();
    }
  }

  protected removeCurrentLocationMarker() {
    if (this.spider && this.currentLocationMarker) {
      this.currentLocationMarker.setMap(null);
    }
  }

  protected subscribeToBoundsChangedEvents() {
    this.boundsSub$ = this.boundsObservable.pipe(takeUntil(this.destroy$)).subscribe(() => this.boundsChangedHandler());
  }

  protected getBoundingBox(): BoundingBox {
    const bounds = this.map.getBounds();
    if (!bounds) {
      return null;
    }

    const northeast = bounds.getNorthEast();
    const southwest = bounds.getSouthWest();
    return {
      northEast: {
        latitude: northeast.lat(),
        longitude: northeast.lng(),
      },
      southWest: {
        latitude: southwest.lat(),
        longitude: southwest.lng(),
      },
    };
  }

  protected getCenter(): SimpleCoordinates {
    const { lat, lng } = this.map.getCenter();
    return { latitude: lat(), longitude: lng() };
  }

  protected reloadPois() {
    // no search performed (yet) or form search happening right now
    if (!this.searchParameters.value || this.controllerService.getBoundsLocked()) {
      return;
    }

    const currentBoundaries = this.getBoundingBox();

    // Exception for technical plz
    const query = isTechPlz(this.searchParameters.value.query) ? this.searchParameters.value.query : null;

    // Construct new search parameters suitable for pan/zoom events without changing previous
    // user form values like need, time, date, etc.
    const searchParameters = Object.assign({}, this.searchParameters.value, {
      fitBounds: false,
      useCurrentLocation: false,
      query,
      location: {
        boundingBox: currentBoundaries,
        center: this.getCenter(),
        geocodedAreaType: GeoCodedAreaType.unknown,
        name: '',
      },
      resetFilter: false,
      zoomLevel: this.map.getZoom(),
      viewportWidth: this.document.documentElement.clientWidth,
      scrollToMap: false,
    } as SearchParameters);

    this.controllerService.startSearch(searchParameters);
  }

  protected createLabel(text: string | number | boolean): google.maps.MarkerLabel {
    if (typeof text !== 'string') {
      text = text.toString();
    }
    return {
      text,
      fontFamily: 'inherit',
      fontWeight: 'bold',
    };
  }

  /**
   * Creates an infowindow based on an angular component as content
   *
   * @param poi PoiListItem or PoiListAggregate
   * @param component An angular component to render as content for the infowindow
   */
  protected createInfoWindow(poi: PoiOrAggregate, component: any): AngularInfoWindow {
    const nativeElemAndRef = this.createInfoWindowContent(poi, component);
    const info = new google.maps.InfoWindow({
      content: nativeElemAndRef.nativeElement,
      disableAutoPan: true,
    }) as AngularInfoWindow;
    info.componentRef = nativeElemAndRef.componentRef;
    info.closeAndDestroy = () => {
      info.close();
      info.componentRef.destroy();
    };
    return info;
  }

  protected attachMarkerMouseOverEvents(item: PoiListItem, marker: SpiderMarker) {
    marker.addListener('mouseover', () => {
      // If the marker is in clustered state, don't show infowindow
      if (marker.status === SpiderfyStatus.spiderifiable) {
        return;
      }

      // Info-Window that shows deadlines and opening times.
      const infoWindow: AngularInfoWindow = this.createInfoWindow(item, PoiInfoWindowComponent);
      infoWindow.open(this.map, marker);
      marker.infoWindow = infoWindow;
      marker.setIcon(this.getActiveIcon(item));
      this.markerMouseEnter(item);
    });
  }

  protected detachMouseOverEvents(marker: SpiderMarker) {
    google.maps.event.clearListeners(marker, 'mouseover');
  }

  protected attachMarkerMouseOutEvents(item: PoiListItem, marker: SpiderMarker) {
    marker.addListener('mouseout', () => {
      if (marker.infoWindow) {
        marker.infoWindow.closeAndDestroy();
      }
      if (marker.status === SpiderfyStatus.spiderifiable) {
        return;
      }
      marker.setIcon(this.getIcon(item));
      this.markerMouseLeave();
    });
  }

  protected detachMouseOutEvents(marker: SpiderMarker) {
    google.maps.event.clearListeners(marker, 'mouseout');
  }

  private unsubscribeFromChangedEvents() {
    if (this.boundsSub$) {
      this.boundsSub$.unsubscribe();
    }
  }

  private poiListChanged(poiList: PoiList) {
    if (this.searchParameters.value && this.searchParameters.value.fitBounds && poiList.extent) {
      // Temporarily suspend bounds_changed listener to prevent unwanted search triggers
      this.unsubscribeFromChangedEvents();

      // Calculate the exact marker bounds because the extent property on the esri response is always bigger and with
      // random padding on all sides. This causes google maps to zoom out and show an even bigger map padding around the markers than
      // necessary. By calculating the exact marker bounds, fitBounds() works a lot more precise without unnecessary zooming.
      // The > 1 bit prevents excessive zooming on the other spectrum if there is but one poi or aggregate. In this case, google would
      // go full macro mode and zoom all the way in.
      let exactBounds = new google.maps.LatLngBounds();
      if (this.searchParameters.value.useCurrentLocation === false) {
        if (poiList.aggregates.length > 1) {
          poiList.aggregates.forEach((aggregate) => exactBounds.extend(toGoogleCoordinates(aggregate.coordinates)));
        }
        if (poiList.pois.length > 1) {
          poiList.pois.forEach((poi) => exactBounds.extend(toGoogleCoordinates(poi.coordinates)));
        }
      }

      // Catch edge case with only one poi or aggregate (e.g. "send letter" in "8000" = 1 aggregate)
      if (exactBounds.isEmpty()) {
        exactBounds = boundingBoxToLatLngBounds(poiList.extent);
      }

      this.map.fitBounds(exactBounds, 0);

      // Wait for fitBounds to finish animating before listening to bounds_changed again
      google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.subscribeToBoundsChangedEvents();
      });

      // Show current location if enabled
      this.controllerService
        .getIsLocationActiveObservable()
        .pipe(take(1))
        .subscribe((x) => this.toggleLocation(x));
    }

    this.showPois(poiList);

    // Remove current location on reset
    if (poiList.extent == null) {
      this.removeCurrentLocationMarker();
    }
  }

  /**
   * Remove old markers/aggregates, add new markers/aggregates
   *
   * @param pois New poilist from the search
   */
  private showPois(pois: PoiList) {
    // The maximum zoom level should not cluster
    let clusterThreshold = 5;
    if (this.zoomLevel >= this.maxZoom) {
      clusterThreshold = 0;
    }

    const clustersOnly = pois.optimizedClustersAsAggregates(clusterThreshold);
    const poisOnly = pois.optimizedPois(clusterThreshold);
    const aggregatesOnly = [...pois.aggregates, ...clustersOnly];

    // Create indices from poilist for fast comparison
    const newPoiIds = poisOnly.reduce((map, poi) => map.set(poi.id, poi), new Map<string, PoiListItem>());
    const newAggIds = aggregatesOnly.reduce((map, agg) => map.set(agg.id, agg), new Map<string, PoiListAggregate>());

    // Remove markers that are no longer on the map
    this.markerIndex.forEach((marker, id) => {
      if (!newPoiIds.has(id)) {
        this.spider.removeMarker(marker);
        this.markerIndex.delete(id);
      }
    });

    // Remove aggregates that are no longer on the map
    this.aggregateIndex.forEach((marker, oldId) => {
      if (!newAggIds.has(oldId)) {
        marker.setMap(null);
        this.aggregateIndex.delete(oldId);
      } else {
        // Aggregate count and position changes between searches (pan/zoom)
        const agg = newAggIds.get(oldId);

        marker.setLabel(this.createLabel(agg.count));
        marker.setPosition(toGoogleCoordinates(agg.coordinates));
      }
    });

    // Add new markers to the map
    aggregatesOnly.forEach((aggregate) => {
      if (!this.aggregateIndex.has(aggregate.id)) {
        this.createAggregate(aggregate);
      }
    });

    poisOnly.forEach((poi) => {
      if (!this.markerIndex.has(poi.id)) {
        this.createMarker(poi);
      }
    });

    // Make drag on marker possible for mobile, see:
    // https://stackoverflow.com/questions/66264523/google-maps-marker-blocks-page-scrolling/74601040
    if (this.map) {
      google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
        document.querySelectorAll(`div[role='button'] img[draggable='false']`).forEach(x => x.addEventListener('touchstart', (e) => {
          e.stopImmediatePropagation();
        }));
      });
    }
  }

  /**
   * Create a marker for an aggregate with infowindow
   *
   * @param item Aggregate
   */
  private createAggregate(item: PoiListAggregate): SpiderMarker {
    const marker: SpiderMarker = new google.maps.Marker({
      icon: {
        url: this.mapService.markerUrl(item),
      },
      position: toGoogleCoordinates(item.coordinates),
      map: this.map,
      label: this.createLabel(item.count),
    });

    marker.addListener('mouseover', () => {
      const infoWindow = this.createInfoWindow(item, AggregateInfoWindowComponent);
      marker.infoWindow = infoWindow;
      infoWindow.open(this.map, marker);
    });

    marker.addListener('mouseout', () => {
      if (marker.infoWindow) {
        marker.infoWindow.closeAndDestroy();
      }
    });

    marker.addListener('click', () => {
      this.aggregateClicked(item);
    });

    this.aggregateIndex.set(item.id, marker);
    marker.status = SpiderfyStatus.undefined;

    return marker;
  }

  /**
   * Create a marker for a poi with infowindow
   *
   * @param item PoiListItem
   */
  private createMarker(item: PoiListItem): SpiderMarker {
    const marker: SpiderMarker = new google.maps.Marker({
      icon: this.getIcon(item),
      position: toGoogleCoordinates(item.coordinates),
      map: this.map,
      zIndex: 0,
    });

    // Fires when map is zoomed, panned or markers are added/removed to change marker icons and status
    // eslint-disable-next-line import/no-deprecated
    marker.addListener('spider_format', (event: SpiderfyStatus) => this.spiderFormat(item, marker, event)); // NOSONAR

    // Open spiderfied markers only when single POI is clicked with spider_click
    // eslint-disable-next-line import/no-deprecated
    marker.addListener('spider_click', () => this.markerClicked(item, marker)); // NOSONAR

    this.attachMarkerMouseOverEvents(item, marker);
    this.attachMarkerMouseOutEvents(item, marker);

    // Add marker to the OverlappingSpiderfier plugin
    this.spider.trackMarker(marker);
    marker.status = SpiderfyStatus.undefined;
    this.markerIndex.set(item.id, marker);

    return marker;
  }

  private aggregateClicked(item: PoiListAggregate) {
    const currentZoom = this.map.getZoom();
    this.map.setZoom(currentZoom + 2);
    this.map.panTo(toGoogleCoordinates(item.coordinates));
  }

  /**
   * Formatted event handler fired when map is zoomed, panned or markers appear/disappear,
   * used to change the icon and status of a poi marker
   *
   * @param item Poi
   * @param marker Map marker
   * @param event Spiderfy status event, one of SpiderfyStatus
   */
  private spiderFormat(item: PoiListItem, marker: SpiderMarker, event: SpiderfyStatus) {
    marker.status = event;

    if (event === SpiderfyStatus.spiderifiable) {
      marker.setIcon(this.mapService.markerUrl(item, true));
      return;
    }

    marker.setIcon(this.mapService.markerUrl(item));
  }

  private createCurrentLocationMarker(coords: SimpleCoordinates): void {
    this.removeCurrentLocationMarker();

    if (!coords) {
      return;
    }

    const accuracyInPixels = getAccuracyInPixels(coords, this.map.getZoom());
    const overlay = createLocationMarker(coords, accuracyInPixels, this.mapService.currentLocationUrl());
    overlay.setMap(this.map);

    this.currentLocationMarker = overlay;
  }

  private initializeMap() {
    window.googleMapsCallback = () => {
      this.googleMapsCallback();
    };
    if (this.disableScriptLoading) {
      const existingGoogleScript =
        document.head.querySelector<HTMLScriptElement>('script[src^=\'https://maps.googleapis.com/maps/\']');
      if (existingGoogleScript) {
        this.logger.debug(`[topos] ~ [topos-map] ~ Found existing google maps scripts!`);
          this.googleMapsCallback();
        return;
      } else {
        this.logger.debug(
          `[topos] ~ [topos-map] ~ You have disable-script-loading set but we cannot find a google maps script on the page!`);
        return;
      }
    }
    const apiKey = this.googleMapsKey !== undefined ? this.googleMapsKey : this.configurationService.getConfiguration().googleMapsApiKey;
    let scriptSrc = this.googleMapsSrc !== undefined ? `${this.googleMapsSrc}&loading=async&callback=googleMapsCallback` : undefined;

    if (!apiKey && !scriptSrc) {
      this.logger.error(`[topos] ~ [topos-map] ~ You have to either configure a Google Maps API key or a custom script source!`);
      return;
    } else if (apiKey && !scriptSrc) {
      // default to standard api
      scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&loading=async&callback=googleMapsCallback`;
    }

    this.logger.debug(`[topos] ~ [topos-map] ~ loading Google Maps API from ${scriptSrc}`);

    if (document.querySelector(`script[src="${scriptSrc}"]`)) {
      this.removeGoogleMapScript();
    }

    const script = document.createElement('script');
    script.src = scriptSrc;
    document.head.appendChild(script);
  }

  private removeGoogleMapScript() {
    const keywords = ['maps.googleapis'];

    //Remove google from BOM (window object)
    window.google = undefined;

    //Remove google map scripts from DOM
    const scripts = document.head.getElementsByTagName('script');
    for (let i = scripts.length - 1; i >= 0; i--) {
      const scriptSource = scripts[i].getAttribute('src');
      if (scriptSource != null) {
        if (keywords.filter(item => scriptSource.includes(item)).length) {
          scripts[i].remove();
        }
      }
    }
  }

  private hoverChanged(poi: PoiOrAggregate) {
    // Mouseout occured (poi is null) or some stranger things are happening
    if (!poi) {
      // remove animation from all pois.
      // performance seems to be ok.
      if (this.poiHovered && this.markerIndex.has(this.poiHovered.id)) {
        const lastHoveredPoi = this.markerIndex.get(this.poiHovered.id);
        lastHoveredPoi.setZIndex(0);
        lastHoveredPoi.setAnimation(-1);
        lastHoveredPoi.setIcon({
          url: this.mapService.markerUrl(this.poiHovered, lastHoveredPoi.status === SpiderfyStatus.spiderifiable),
        });
      }

      this.poiHovered = null;

      return;
    }

    // no change, do nothing.
    // Also used to prevent bouncing when event is triggered from map, no bouncing
    // in this case.
    if (this.poiHovered && this.poiHovered.id === poi.id) {
      return;
    }

    // mousepointer is now over a poi (poi id is not null)
    this.poiHovered = poi;
    const marker = this.markerIndex.get(poi.id);

    if (marker) {
      marker.setZIndex(10);
      marker.setAnimation(google.maps.Animation.BOUNCE);

      const isCluster = marker.status === SpiderfyStatus.spiderifiable;
      marker.setIcon({
        url: this.mapService.markerUrl(poi, isCluster, true),
      });
    }
  }

  // Getting html from dynamically created components
  // https://juristr.com/blog/2017/11/dynamic-angular-components-for-rendering-html/
  private createInfoWindowContent(poi: PoiOrAggregate, component: any): { nativeElement: any; componentRef: ComponentRef<any> } {
    const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory<any>(component);
    const componentHTML: ComponentRef<any> = factory.create(this.injector);

    componentHTML.instance.poi = poi;
    componentHTML.changeDetectorRef.detectChanges();

    return {
      nativeElement: componentHTML.location.nativeElement,
      componentRef: componentHTML,
    };
  }

  private zoomLevelChanged(zoomLevel: number) {
    this.locationService
      .isLocationServiceEnabled()
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationEnabled: boolean) => {
        if (locationEnabled) {
          this.locationService
            .getLocation()
            .pipe(takeUntil(this.destroy$))
            .subscribe((coords: SimpleCoordinates) => {
              let accuracyInPixels = getAccuracyInPixels(coords, zoomLevel);
              if (accuracyInPixels <= 28) {
                accuracyInPixels = 28;
              }
              document.documentElement.style.setProperty('--pulse-size', accuracyInPixels * 2 + 'px');
            });
        }
      });

    this.zoomLevel = zoomLevel;
  }


  private googleMapsCallback() {
    this.configurationService
      .getMapStyles()
      .pipe(takeUntil(this.destroy$))
      .subscribe((styles: google.maps.MapTypeStyle[]) => {
        this.logger.debug(`[topos] ~ [topos-map] ~ Running googleMapsCallback!`);
        const config = this.configurationService.getConfiguration();
        this.map = new google.maps.Map(this.mapElement.nativeElement, {
          center: {
            lat: config.defaultCenterOfMap.latitude,
            lng: config.defaultCenterOfMap.longitude,
          },
          zoom: config.defaultZoomLevel,
          styles,
          clickableIcons: false,
          mapTypeControl: false,
          maxZoom: this.maxZoom,
          gestureHandling: this.zoomGestureHandling,
          fullscreenControl: !this.disableFullscreen,
          streetViewControl: !this.disableStreetView,
          zoomControl: !this.disableZoom
        });

        this.spider = new OverlappingMarkerSpiderfier(this.map, {
          markersWontMove: true,
          markersWontHide: true,
          circleFootSeparation: 50, // At least one icon width apart
          spiralFootSeparation: 10,
          nearbyDistance: 50,
        });

        this.spider.legColors.highlighted[google.maps.MapTypeId.ROADMAP] = '#ffcc00';

        this.boundsObservable = this.createBoundsObservable();
        this.subscribeToBoundsChangedEvents();

        this.createZoomObservable()
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            const zoom = this.map.getZoom();
            this.controllerService.setZoomLevel(zoom);
          });

        if (this.mapObserver) {
          this.mapObserver.observe(this.mapElement.nativeElement);
        }

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
          const currentBoundaries = this.getBoundingBox();
          this.controllerService.setMapBoundaries(currentBoundaries);

          this.startFirstSearch();
        });
      });
  }

  /**
   * Create an observable which emits changes to google map bounds, debounced by 150ms.
   * There is also an automatic teardown which removes the event listener from google maps which enables
   * functions in this component to start and stop listening to these events at will and with ease. It's
   * handy during fitBounds() where bounds_changed events should be ignored to prevent additional searches
   * from being triggered
   */
  private createBoundsObservable(): Observable<unknown> {
    return new Observable((subscriber) => {
      const boundsChangedListener = this.map.addListener('bounds_changed', () => subscriber.next());

      return () => {
        if (boundsChangedListener) {
          google.maps.event.removeListener(boundsChangedListener);
        }
      };
    }).pipe(debounceTime(150));
  }

  /**
   * Creates an observable which emits changes to google map zoom events.
   * Automatic teardown.
   */
  private createZoomObservable(): Observable<unknown> {
    return new Observable((subscriber) => {
      const zoomChangedListener = this.map.addListener('zoom_changed', () => subscriber.next());
      return () => {
        if (zoomChangedListener) {
          google.maps.event.removeListener(zoomChangedListener);
        }
      };
    });
  }

  private boundsChangedHandler() {
    const currentBoundaries = this.getBoundingBox();
    this.controllerService.setMapBoundaries(currentBoundaries);
    this.reloadPois();
  }

  protected abstract markerClicked(item: PoiListItem, marker: SpiderMarker): void;
  protected abstract startFirstSearch(): void;
}
