import { Directive, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { ControllerService } from 'src/app/controller.service';
import { BaseLocationService } from 'src/app/gis/location/base.location.service';
import { SimpleCoordinates } from 'src/app/gis/model/simplecoordinates';
import { MapService } from 'src/app/gis/services/map.service';
import { distInKm } from 'src/app/gis/util/coordinatesutil';
import { PoiListItem, PoiOrAggregate } from '@app/app/gis/model/poibase';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseResultListItemComponent implements OnInit, OnDestroy {
  public hoveringActive = false;
  public locationServiceEnabled = false;
  public distanceToCurrentLocation: number = null;

  private intervalSubscription: Subscription = null;
  private poiHoveredSubscription: Subscription = null;
  private locationInitSub$: Subscription;
  private _poiListItem: PoiListItem;

  constructor(
    protected controllerService: ControllerService,
    protected locationService: BaseLocationService,
    protected mapService: MapService,
  ) {
    this.poiHoveredSubscription = this.controllerService
      .getHoveredPoiObservable()
      .subscribe((poi: PoiOrAggregate) => this.hoverChanged(poi));
  }

  get poiListItem(): PoiListItem {
    return this._poiListItem;
  }

  @Input()
  set poiListItem(poilistItem: PoiListItem) {
    this._poiListItem = poilistItem;
    this.initCurrentCoordinates();
  }

  ngOnInit(): void {
    this.initLocationService();
  }

  ngOnDestroy(): void {
    if (this.locationInitSub$) {
      this.locationInitSub$.unsubscribe();
    }
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    if (this.poiHoveredSubscription) {
      this.poiHoveredSubscription.unsubscribe();
    }
  }

  public iconClass(): string {
    return `pi-${this.mapService.iconUrl(this.poiListItem)}`;
  }

  private initLocationService() {
    this.locationInitSub$ = this.controllerService
      .getIsLocationActiveObservable()
      .pipe(take(1))
      .subscribe((locationActive) => {
        if (locationActive) {
          // Show distance from user to poi if location service is enabled.
          this.locationService.getLocation().subscribe((x: SimpleCoordinates) => this.calculateDistToPoi(x));

          // Refresh distance from user to poi if location service is enabled.
          this.intervalSubscription = this.controllerService.getUpdateDataInterval().subscribe(() => {
            this.locationService.isLocationServiceEnabled().subscribe((locationEnabled: boolean) => {
              if (locationEnabled) {
                this.locationService.getLocation().subscribe((x: SimpleCoordinates) => this.calculateDistToPoi(x));
              }
            });
          });
        } else {
          // Remove distance from user to poi
          this.locationServiceEnabled = false;
        }
      });
  }

  private initCurrentCoordinates() {
    if (this.locationServiceEnabled) {
      this.locationService.getLocation().subscribe((x: SimpleCoordinates) => this.calculateDistToPoi(x));
    }
  }

  private calculateDistToPoi(coordinates: SimpleCoordinates) {
    if (this.poiListItem && coordinates && coordinates.accuracy && coordinates.accuracy <= 1000) {
      this.distanceToCurrentLocation = distInKm(coordinates, this.poiListItem.coordinates);
      this.locationServiceEnabled = true;
    } else {
      this.locationServiceEnabled = false;
    }
  }

  abstract itemClicked(evt: Event): void;
  abstract hoverChanged(poi: PoiOrAggregate): void;
  abstract mouseEnter(): void;
  abstract mouseLeave(): void;
}
