import { AfterContentInit, Component, Input } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';
import { PoiDetail } from 'src/app/gis/model/poidetail/poidetail';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Flickity: any;
  }
}

@Component({
  selector: 'app-detail-tabs',
  templateUrl: './detail-tabs.component.html',
})
export class DetailTabsComponent implements AfterContentInit {
  @Input() active = 1;
  @Input() poi: PoiDetail;

  private flkty = null;

  private tabs: { id: number; key: string }[] = [
    { id: 1, key: 'detail.hours.hours' },
    { id: 2, key: 'detail.hours.deadline' },
    { id: 3, key: 'detail.services.service' },
    { id: 4, key: 'detail.addressing.pickpostAndPostlagernd' },
    { id: 5, key: 'detail.partners.offers' },
  ];

  constructor(
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly tagManagerService: TagManagerService
  ) { }

  ngAfterContentInit() {
    this.active = this.getFirstActiveTab(this.poi);
  }

  public getTabKey(id: number) {
    return this.tabs.find((e) => e.id === id).key;
  }

  public navigationChange(event: NgbNavChangeEvent) {
    forkJoin([this.translateExtendedService.get(this.getTabKey(event.nextId)),
    this.translateExtendedService.getGermanTranslation(this.getTabKey(event.nextId))])
      .subscribe(([navTitle, navTitleGer]) => {
        /* eslint-disable @typescript-eslint/naming-convention */
        this.tagManagerService.click({
          event: 'select_menu',
          type: 'tab',
          label: navTitleGer.toLowerCase().replace(/\s/g, '-'),
          text: navTitle.toLowerCase(),
          additional_info: 'poi'
        });
        /* eslint-enable @typescript-eslint/naming-convention */
      }
      );
  }

  public getFirstActiveTab(poi: PoiDetail): number {
    if (poi.hasOpeningHours) {
      return 1;
    } else if (poi.hasDeadlines) {
      return 2;
    } else if (poi.hasServices) {
      return 3;
    } else if (poi.hasAdresses) {
      return 4;
    } else if (poi.hasPartners) {
      return 5;
    }
  }

  private handleClick(event: Event) {
    event.preventDefault();
  }

  private selectFlickitySlide(id: number) {
    this.flkty.select(id);
  }
}
