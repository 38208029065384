<div class="container my-4">
  <div class="row">
    <div class="col-md-6 mb-4">
      <h1 class="bold font-curve-large">{{ poi.description }}</h1>
      <h2 class="light font-curve-large" *ngIf="poi.additionalDescription">{{ poi.additionalDescription }}</h2>

      <!-- Address -->
      <address class="font-curve-regular light mt-4">
        <p>
          {{ poi.street }}
          <br>
          {{ poi.zip }} {{ poi.city }}
        </p>
        <p *ngIf="poi.phone || poi.fax">
          <span *ngIf="poi.phone"><span translate>detail.address.tel</span> {{ poi.phone }}</span>
          <br *ngIf="poi.phone && poi.fax">
          <span *ngIf="poi.fax"><span translate>detail.address.fax</span> {{ poi.fax }}</span>
        </p>
      </address>

      <!-- Post appointment button -->
      <div *ngIf="poi.postAppointmentLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi.postAppointmentLink" class="btn btn-primary map--button mr-3 mb-3" target="_blank" (click)="trackPostLinkClick($event)"
          translate>detail.postappointmentbutton</a>
      </div>

      <!-- Postfinance appointment button -->
      <div *ngIf="poi.postFinanceAppointmentLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi.postFinanceAppointmentLink" class="btn btn-primary map--button mr-3 mb-3" target="_blank" (click)="trackPfLinkClick($event)"
          translate>detail.postfinanceappointmentbutton</a>
        <span class="badge badge-pill badge-outline-gray-carrara-thick info-badge mb-3"
          [ngbTooltip]="'detail.postfinanceappointmentinfo' | translate">
          <i class="pi pi-small pi-2106" aria-hidden="true"></i>
        </span>
      </div>

      <!-- Self-service point business client offer button -->
      <div *ngIf="poi?.servicePointBusinessOfferLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.servicePointBusinessOfferLink" class="btn btn-primary map--button mr-3 mb-3" target="_blank" (click)="trackSpbLinkClick($event)"
          translate>detail.servicepointbusinessofferbutton</a>
      </div>

      <!-- MP24 without display manual button -->
      <div *ngIf="poi?.mp24WithoutDisplayLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.mp24WithoutDisplayLink" class="btn btn-primary map--button mr-3 mb-3" target="_blank" (click)="trackMp24LinkClick($event)"
          translate>detail.mp24withoutdisplaymanualbutton</a>
      </div>

      <!-- Postfachanlage offer button -->
      <div *ngIf="poi?.postfachanlageOfferLink" class="d-flex align-items-center flex-wrap">
        <a [href]="poi?.postfachanlageOfferLink" class="btn btn-primary map--button mr-3 mb-3" target="_blank" (click)="trackPfstLinkClick($event)"
          translate>detail.postfachanlageofferbutton</a>
      </div>

      <app-hint [hints]="poi.hints"></app-hint>
    </div>
    <div class="col-md-6 detail--static-map">
      <app-static-map [poi]="poi"></app-static-map>
    </div>
  </div>

  <app-detail-tabs [poi]="poi" class="mt-4 d-block"></app-detail-tabs>
</div>
