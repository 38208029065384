<div class="app-search-component row justify-content-center">
  <form class="col-xl-12" [formGroup]="form" #formDir="ngForm" (ngSubmit)="submitSearch()">
    <div class="row">

      <!-- Needs select -->
      <div class="col-md-6 custom-row order-1" *ngIf="!hiddenNeedMode">
        <div class="form-group mb-0" [ngClass]="defaultNeed ? 'custom-w-100' : 'custom-flex-50'">
          <select id="search-component-needs" class="form-control form-control-lg custom-select"
            [ngClass]="{'custom-select-border1': !defaultNeed}" formControlName="needId" required
            [title]="selectedNeedText | async">
            <option *ngFor="let need of visibleNeeds" [value]="need.id">
              {{ getTranslatedNeedText(need) }}
            </option>
          </select>
          <label class="z-index-4" for="search-component-needs" translate>search.selectneed</label>
        </div>
        <div [ngClass]="defaultNeed ? 'w-0': 'custom-flex-50'">
          <div class="form-group" [ngClass]="defaultNeed ? 'd-none': 'd-block'">
            <div id="borderLeft"></div>
            <select *ngIf="secondlevelneeds.length !== 1" id="search-component-secondlevelneed"
              class="form-control form-control-lg custom-select-border2" formControlName="secondLevelNeed" required>
              <option *ngFor="let secondlevelneed of secondlevelneeds" [value]="secondlevelneed.id">
                {{ getTranslatedSecondlevelneedText(secondlevelneed) }}
              </option>
            </select>
            <div *ngIf="secondlevelneeds.length === 1" class="custom-search-control-div custom-select-border2">
              <label class="custom-search-control-label">{{ getTranslatedSecondlevelneedText(secondlevelneeds[0])
                }}</label>
            </div>
            <label *ngIf="needId && needId === searchLocationNeedId" class="custom-search-control-toplabel z-index-4" for="search-component-secondlevelneed"
              >{{selectLocationText}}</label>
            <label *ngIf="needId && needId !== searchLocationNeedId" class="custom-search-control-toplabel z-index-4" for="search-component-secondlevelneed">{{selectSecondLevelNeedText}}</label>
          </div>
        </div>
      </div>

      <!-- Hiden need -->
      <div class="col-md-6 custom-row order-1" *ngIf="hiddenNeedMode">
        <div class="form-group w-100">
          <div class="custom-search-control-div">
            <label class="custom-search-control-label" translate>{{ getTranslatedNeedText(selectedHiddenNeed) }}</label>
          </div>
          <label class="custom-search-control-toplabel z-index-4" for="search-component-secondlevelneed"
            translate>search.yourneed</label>
        </div>
      </div>

      <!-- Point in time select -->
      <div class="col-md-3 col-sm-12 order-2 order-md-2 order-sm-last">
        <div class="form-group">
          <div #pointintimeDropdown="ngbDropdown" ngbDropdown placement="bottom-left"
            (openChange)="initFlickity($event)">
            <button id="search-component-pointintime" type="button" class="custom-search-control-div move-arrow"
              ngbDropdownToggle>
              <label *ngIf="allTimes" class="custom-search-control-label" translate>common.pointintime.alltasks</label>
              <label *ngIf="!allTimes" class="custom-search-control-label">{{pointintimeString}}</label>
            </button>
            <label class="custom-search-control-toplabel" for="search-component-pointintime"
              translate>search.pointintime</label>

            <div ngbDropdownMenu aria-labelledby="search-component-pointintime"
              class="search-component-pointintime-dropdown">
              <form class="p-3" [formGroup]="timeForm" (ngSubmit)="submitTime()">

                <!-- Date -->
                <label translate>search.label.date</label>
                <app-date-slider [dates]="dates" [value]="selectedDate"
                  (dateChange)="onDateChange($event)"></app-date-slider>

                <!-- Time -->
                <div class="form-group">
                  <label for="search-component-time" translate>search.label.time</label>
                  <input type="text" id="search-component-time" class="form-control" placeholder="Zeit"
                    formControlName="time"
                    [class.is-invalid]="timeForm.get('time').invalid && timeForm.get('time').dirty">
                  <label for="search-component-time" class="invalid-feedback">
                    <span *ngIf="timeForm.get('time').errors?.required" translate>search.validation.required</span>
                    <span *ngIf="timeForm.get('time').errors?.pattern" translate>search.validation.timeformat</span>
                  </label>
                </div>

                <div class="form-group mb-2 pt-3 text-center">
                  <button type="button" class="btn btn-link btn-rg" (click)="resetTimeForm()">{{'common.reset' |
                    translate}}</button>
                  <button type="submit" class="btn btn-primary btn-rg" translate>common.confirm</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3 order-3">

        <!-- PLZ/Place freetext -->
        <div class="form-group location-control">

          <ng-template #resultTemplatePlzPlace let-r="result" let-t="term">
            <!-- Show crosshair icon with current location item -->
            <i class="pi pi-2029" aria-hidden="true" *ngIf="r.geocodedAreaType === currentLocationAreaType"></i>
            {{r.name}}
          </ng-template>

          <input formControlName="location" id="search-component-zip" class="form-control form-control-lg"
            [ngbTypeahead]="autocompletePlzPlace" [resultTemplate]="resultTemplatePlzPlace"
            [inputFormatter]="formatterPlzPlace" [focusFirst]="false" (focus)="showAutocomplete($event)"
            (selectItem)="selectedPlzPlace($event)" placeholder="PLZ/Ort">
          <label for="search-component-zip" translate>search.location</label>

          <!-- Current location -->
          <button type="button" class="btn btn-default btn-icon btn-lg location-control-button"
            (click)="onCurrentLocationClick($event)">
            <i class="pi pi-2029" aria-hidden="true" *ngIf="!useCurrentLocation"></i>
            <i class="pi pi-2043" aria-hidden="true" *ngIf="useCurrentLocation"></i>
            <span class="sr-only" translate>search.usecurrentlocation</span>
          </button>
        </div>
      </div>
    </div>

    <div class="mt-2 mb-4 d-flex align-items-center search-submit-row">
      <button type="submit" [disabled]="searchButtonDisabled" class="btn btn-primary btn-animated search-button"><span
          translate>common.search</span></button>
      <button type="button" *ngIf="form.dirty || selectedDate !== null" class="btn btn-link reset-button btn-icon"
        (click)="resetForm()"><i class="pi pi-2042" aria-hidden="true"></i> {{'common.reset' | translate}}</button>
    </div>
  </form>
</div>

<div id="search-component-alert" tabindex="-1" style="z-index: 2;"
  class="alert alert-info alert-action pi-3137 collapse" role="alert">
  <div class="alert-content">
    <h4 class="alert-heading" translate>search.alert.location.title</h4>
    <p translate>
      search.alert.location.message
    </p>
  </div>
  <div class="alert-buttons">
    <button class="btn btn-primary btn-animated" type="button" (click)="openManual()"
      translate>search.alert.location.manual</button>
    <button class="btn btn-outline-secondary btn-animated btn-notnow" type="button" (click)="hideFixedAlert()"
      translate>search.alert.location.notnow</button>
  </div>
</div>