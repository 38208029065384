import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-slider',
  templateUrl: './date-slider.component.html',
  styleUrls: ['./date-slider.component.scss'],
})
export class DateSliderComponent implements OnDestroy {
  @Input() dates: Date[];
  @Input() value: Date;
  @Output() dateChange = new EventEmitter<Date>();

  private flickity: any;

  constructor(private readonly translateService: TranslateService) {}

  ngOnDestroy(): void {
    this.destroyFlickitiy();
  }

  public initFlickity() {
    // This part serves as a workaround until flickity provides
    // the possibility to set aria labels in the options parameter
    // https://github.com/metafizzy/flickity/pull/886
    window.Flickity.defaults.pageDotAriaLabel = 'Page dot %n';
    this.translateService
      .get('search.date.previous')
      .subscribe((x) => {
        window.Flickity.defaults.previousAriaLabel = x;
      })
      .unsubscribe();
    this.translateService
      .get('search.date.next')
      .subscribe((x) => {
        window.Flickity.defaults.nextAriaLabel = x;
      })
      .unsubscribe();

    const addDots = window.Flickity.PageDots.prototype.addDots;
    window.Flickity.PageDots.prototype.addDots = function() {
      addDots.apply(this, arguments);

      const ariaLabel = this.parent.options.pageDotAriaLabel;
      this.dots.forEach((dot, i) => {
        const label = ariaLabel.replace('%n', i + 1);
        dot.setAttribute('aria-label', label);
      });
    };

    const _createButton = window.Flickity.PrevNextButton.prototype._create;
    window.Flickity.PrevNextButton.prototype._create = function() {
      _createButton.apply(this, arguments);
      const optionName = this.isPrevious ? 'previous' : 'next';
      const label = this.parent.options[optionName + 'AriaLabel'];
      this.element.setAttribute('aria-label', label);
    };

    // Timeout "waits" for the browser to render the elements to the DOM
    window.setTimeout(() => {
      this.flickity = new window.Flickity('.date-slider', {
        alignCells: 'center',
        contain: true,
        pageDots: false,
        draggable: true,
        freeScroll: true,
        groupCells: true,
        percentPosition: false,
        initialIndex: `[data-slide-index="${this.dates.indexOf(this.value)}"]`,
      });
    }, 5);
  }

  public destroyFlickitiy() {
    if (this.flickity) {
      this.flickity.destroy();
    }
  }

  public onChange(event: Event) {
    this.dateChange.emit(this.dates[(event.target as HTMLInputElement).value]);
  }
}
