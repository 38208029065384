<div>

  <!-- deadlines today -->
  <div *ngIf="showDeadlinesToday === true" class="py-4">
    <h3 class="font-curve-regular bold mb-4" translate>
      <span translate>detail.hours.deadlineToday</span>
      <button class="info-button ml-1" [ngbPopover]="'common.deadlineinfo' | translate">
        <i class="pi pi-info" aria-hidden="true"></i>
      </button>
    </h3>
    <p class="bg-info p-3 hint" *ngIf="infoText" translate>
      {{infoText}}
    </p>
    <div class="row" *ngIf="!infoText">
      <div class="col-rg-6" *ngFor="let deadlineToday of poi?.deadlinesToday">
        <div class="time-item">
          <h4 class="font-curve-small bold">{{deadlineToday.productTypeName}}</h4>

          <p class="font-curve-small" [class.text-confirmation]="deadlineToday.deadLineStillOk === true"
            [class.text-danger]="deadlineToday.deadLineStillOk === false">
            <span class="bold">
              {{deadlineToday.timeString}}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- deadlines at a specific date -->
  <div *ngIf="showDeadlinesAtDate === true" class="py-4">
    <h3 class="bold font-curve-regular mb-4">
      <span>{{deadlinesAtTitle}}</span>
      <span *ngIf="openingHoursAtHoliday">{{openingHoursAtHoliday}}</span>
      <button class="info-button ml-1" [ngbPopover]="'common.deadlineinfo' | translate">
        <i class="pi pi-info" aria-hidden="true"></i>
      </button>
    </h3>
    <p class="bg-info p-3 hint" *ngIf="infoText" translate>
      {{infoText}}
    </p>
    <div class="row" *ngIf="!infoText">
      <div class="col-rg-6" *ngFor="let deadlineProductAtDate of deadlinesProductAtDate">
        <div class="time-item">
          <h4 class="font-curve-small bold">{{deadlineProductAtDate.productTypeName}}</h4>

          <p class="font-curve-small">
            <span *ngFor="let timeString of deadlineProductAtDate.timeStrings">
              {{timeString}}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- deadlines in the next seven days -->
  <ngb-accordion #acc="ngbAccordion" activeIds="deadlinePanel" [destroyOnHide]="false" (panelChange)="trackPanelChange($event)">
    <ngb-panel id="deadlinePanel">
      <ng-template ngbPanelTitle>
        <h3 class="bold font-curve-regular mb-0">{{'detail.hours.deadlines' | translate}}</h3>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="row">
          <div class="col-rg-6" *ngFor="let deadlineForProduct of poi?.deadlinesForProduct">
            <div class="time-item font-curve-small">
              <h4 class="font-curve-small bold">{{deadlineForProduct.productTypeName}}</h4>
              <div *ngFor="let dayOpeningHour of deadlineForProduct.daysOpeningHours" class="row">
                <div class="col-6">
                  {{dayOpeningHour.daysText}}
                </div>
                <div class="col-6">
                  {{dayOpeningHour.timeString}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
