import { Directive, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslateExtendedService } from '@app/app/common/translate-extended.service';
import { ConfigurationService } from '@app/app/config/configuration.service';
import { ControllerService } from '@app/app/controller.service';
import { BaseLocationService } from '@app/app/gis/location/base.location.service';
import { GeocodedArea } from '@app/app/gis/model/geocodedArea';
import { GeoCodedAreaType } from '@app/app/gis/model/geocodedAreaType';
import { NeedConfig } from '@app/app/gis/model/needconfig';
import { GisService } from '@app/app/gis/services/gis.service';
import { NeedsCacheService } from '@app/app/gis/services/needs-cache.service';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseSearchComponent implements OnInit, OnDestroy {
  public needs: NeedConfig[] = [];
  public locationServiceEnabled = false;
  public useCurrentLocation = false;
  public manualUrl = '';
  public isReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private locationInitSub$: Subscription;
  private needInitSub$: Subscription;

  public abstract form: FormGroup;

  constructor(
    protected locationService: BaseLocationService,
    protected translateExtendedService: TranslateExtendedService,
    protected needsService: NeedsCacheService,
    protected gisService: GisService,
    protected controllerService: ControllerService,
    protected configurationService: ConfigurationService,
    protected logger: NGXLogger
  ) {
    this.manualUrl = this.configurationService.getConfiguration().locationPermissionManualUrl;
  }

  ngOnInit() {
    this.locationInitSub$ = this.locationService.isLocationServiceEnabled().subscribe((locationEnabled: boolean) => {
      this.locationServiceEnabled = locationEnabled;
    });

    this.needInitSub$ = this.needsService.getNeeds().subscribe((needs) => {
      this.logger.debug('got needs from needs-cache.service');
      this.needs = needs;
      this.isReady$.next(true);
    });
  }

  ngOnDestroy() {
    if (this.locationInitSub$) {
      this.locationInitSub$.unsubscribe();
    }

    if (this.needInitSub$) {
      this.needInitSub$.unsubscribe();
    }

    if (this.isReady$) {
      this.isReady$.unsubscribe();
    }
  }

  /// Toggles the location service on/off
  public toggleCurrentLocation() {
    this.locationService.askForPermission().subscribe((locationEnabled: boolean) => {
      if (locationEnabled) {
        const alert = document.getElementById('search-component-alert');
        alert.classList.add('collapse');

        this.useCurrentLocation = !this.useCurrentLocation;
      } else {
        const alert = document.getElementById('search-component-alert');
        alert.classList.remove('collapse');
        alert.focus();

        this.useCurrentLocation = false;
      }
      this.controllerService.setLocationIsActive(this.useCurrentLocation);

      const locationControl: AbstractControl = this.form.get('location');
      if (this.useCurrentLocation) {
        this.translateExtendedService.get('search.currentlocation').subscribe((currLocationText) => {
          const area = { name: currLocationText };
          locationControl.setValue(area);
          locationControl.disable();
        });
      } else {
        locationControl.setValue('');
        locationControl.enable();
      }
    });
  }

  public onCurrentLocationClick(event: MouseEvent) {
    event.preventDefault();
    this.toggleCurrentLocation();
  }

  public openManual() {
    window.open(this.manualUrl);
  }

  public hideFixedAlert() {
    const toposContainer = document.querySelector('topos-container');
    const documentDom = toposContainer ? toposContainer.shadowRoot : document;
    const alert = documentDom.getElementById('search-component-alert');
    alert.classList.add('collapse');
  }

  public autocompletePlzPlace = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((term: string) => this.geocode(term))
    );

  /// The string result of this function will be shown inside the textarea
  /// if the user selects an item in the autocomplete
  public formatterPlzPlace(item: GeocodedArea): string {
    return item.name;
  }

  /// Shows the autocomplete
  public showAutocomplete(e): void {
    let inputEvent;
    try {
      // This does not work in IE
      inputEvent = new Event('input');
    } catch {
      // IE shows dropdown anyways (by mistake?), but we can abuse this here
    }
    if (inputEvent) {
      e.target.dispatchEvent(inputEvent);
    }
  }

  /// Enables the location service if selected in the autocomplete
  public selectedPlzPlace($event: NgbTypeaheadSelectItemEvent) {
    if ($event.item.geocodedAreaType === GeoCodedAreaType.currentLocation) {
      this.toggleCurrentLocation();
    }
  }

  protected abstract geocode(term: string): Observable<GeocodedArea[]>;
}
