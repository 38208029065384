<div class="tabs-wrapper">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" (navChange)="navigationChange($event)" class="tabs" id="detail-tabset" role="tablist">

    <!-- Öffungszeiten -->
    <li [ngbNavItem]="1" id="tab-selectbyid1" *ngIf="poi.hasOpeningHours" role="presentation">
      <a href="#" (click)="handleClick($event)" ngbNavLink class="tab-title" role="tab" translate>{{ getTabKey(1) }}</a>
      <ng-template ngbNavContent>
        <app-openinghours [poi]="poi"></app-openinghours>
      </ng-template>
    </li>

    <!-- Annahmeschluss -->
    <li [ngbNavItem]="2" id="tab-selectbyid2" *ngIf="poi.hasDeadlines" role="presentation">
      <a href="#" (click)="handleClick($event)" ngbNavLink class="tab-title" role="tab" translate>{{ getTabKey(2) }}</a>
      <ng-template ngbNavContent>
        <app-deadlines [poi]="poi"></app-deadlines>
      </ng-template>
    </li>

    <!-- Services -->
    <li [ngbNavItem]="3" id="tab-selectbyid3" *ngIf="poi.hasServices" role="presentation">
      <a href="#" (click)="handleClick($event)" ngbNavLink class="tab-title" role="tab" translate>{{ getTabKey(3) }}</a>
      <ng-template ngbNavContent>
        <app-services [poi]="poi"></app-services>
      </ng-template>
    </li>

    <!-- Adressierung PickPost & Postlagernd -->
    <li [ngbNavItem]="4" id="tab-selectbyid4" *ngIf="poi.hasAdresses" role="presentation">
      <a href="#" (click)="handleClick($event)" ngbNavLink class="tab-title" role="tab" translate>{{ getTabKey(4) }}</a>
      <ng-template ngbNavContent>
        <app-addressing [poi]="poi"></app-addressing>
      </ng-template>
    </li>

    <!-- Partnerangebote -->
    <li [ngbNavItem]="5" id="tab-selectbyid5" *ngIf="poi.hasPartners" role="presentation">
      <a href="#" (click)="handleClick($event)" ngbNavLink class="tab-title" role="tab" translate>{{ getTabKey(5) }}</a>
      <ng-template ngbNavContent>
        <app-partners [poi]="poi"></app-partners>
      </ng-template>
    </li>
    
  </ul>
</div>

<div [ngbNavOutlet]="nav" class="p-3"></div>