import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@swisspost/internet-header';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

defineCustomElements();

if (environment.production) {
  enableProdMode();
}

const providers = [];

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic([providers]).bootstrapModule(AppModule);
});
