import { Component } from '@angular/core';
import { TagManagerService } from '@app/app/common/tag.service';
import { TranslateExtendedService } from '@app/app/common/translate-extended.service';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import { ControllerService } from 'src/app/controller.service';

@Component({
  selector: 'app-detailcontainer',
  templateUrl: './detailcontainer.component.html',
  styleUrls: ['./detailcontainer.component.scss'],
})
export class DetailcontainerComponent {
  public visible = false;

  constructor(
    private readonly controllerService: ControllerService,
    private readonly translateService: TranslateService,
    private readonly translateExtendedService: TranslateExtendedService,
    private readonly tagManagerService: TagManagerService
  ) {
    this.controllerService.getPoiSelectedObservable().subscribe((poi) => {
      if (!poi) {
        return;
      }
      this.showDialog();
    });
  }

  public showDialog() {
    this.visible = true;
  }

  public closeDialog() {
    this.visible = false;
    if (history) {
      history.back();
    }

    // focus current poi result list item for keyboard users (desktop only)
    if (window && window.innerWidth > 1024) {
      this.controllerService.getPoiSelectedObservable().subscribe((resultListItem) => {
        document.getElementById(`result-${resultListItem?.id}`).focus();
      });
    }

    forkJoin([this.translateExtendedService.get('common.application.title'),
    this.translateExtendedService.getGermanTranslation('common.application.title')])
    .subscribe(([title, titleGer]) => {
      this.tagManagerService.change({
        event: 'page_change',
        pageId: 'home',
        pageName: title,
        unifiedPageName: titleGer,
        pageType: 'home',
      });
    });
  }

  public stopBubbling(event: MouseEvent) {
    event.stopPropagation();
  }
}
