import { CookieService } from 'ngx-cookie';
import { configuraton } from 'src/app/config/configuration';

export let LANG_COOKIE_KEY = 'topos.app.language';
export let LANG_STORAGE_KEY = 'topos.app.language';
export abstract class BaseLanguageService {

    protected pathLanguages = [
        { code: 'it', regex: '\/it\/' },
        { code: 'en', regex: '\/en\/' },
        { code: 'fr', regex: '\/fr\/' },
        { code: 'de', regex: '\/de\/' },
    ];

    protected defaultLanguage = 'de';

    constructor(protected readonly cookieService: CookieService) {
        // Add home routes to path languages so localization works on home
        Object.entries(configuraton.homeRoutes)
            .forEach(([key, value]) => {
                this.pathLanguages.push({ code: key, regex: `\/${value}`});
            });
    }

    setCookieKey(cookieKey: string) {
        LANG_COOKIE_KEY = cookieKey;
    }

    setLocalSorageKey(localStorageKey: string) {
        LANG_STORAGE_KEY = localStorageKey;
    }

    getFromCookie() {
        return this.cookieService.get(LANG_COOKIE_KEY);
    }

    protected sanitizeLanguage(language: string): string {
        const twoLetterLanguage = language.length > 2 ? language.substring(0, 2) : language;
        return this.isSupportedLanguage(twoLetterLanguage) ? twoLetterLanguage : this.defaultLanguage;
    }

    protected isSupportedLanguage(language: any): boolean {
        if (language == null) {
            return false;
        }

        return language.match(/en|fr|it|de/);
    }

    protected getFromUrlParameter(name: string, url: string): string {
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results || !results[2]) {
            return null;
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    protected getFromUrlPath(url: string): string {
       return this.pathLanguages.find( pathLanguage => url.match(pathLanguage.regex))?.code;
    }

    protected saveLanguageInCookie(language: string) {
        const twoYearsCookie = new Date();
        twoYearsCookie.setFullYear(twoYearsCookie.getFullYear() + 2);
        this.cookieService.put(LANG_COOKIE_KEY, language, { expires: twoYearsCookie, path: '/', httpOnly: false });
    }

    abstract getLanguage(): string;
    abstract setLanguage(language: string): string;
}
