import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HeaderConfigService {
  /**
   * Set the language specific alternate links for the SwissPost Internet Header
   *
   * @param languageUrls [{ lang: 'de', url: 'https://places.post.ch/standortsuche' }, { lang: 'fr', url: 'https...
   */
  public writeHeaderConfiguration(languageUrls) {
    const header: HTMLSwisspostInternetHeaderElement = document.querySelector('swisspost-internet-header');
    if (header) {
      header.languageSwitchOverrides = languageUrls;
    }
  }
}
