import { Component } from '@angular/core';
import { BaseResultListItemComponent } from '@app/app/base/components/base-result-item.component';
import { TagManagerService } from '@app/app/common/tag.service';
import { PoiOrAggregate } from '@app/app/gis/model/poibase';
import { ControllerService } from 'src/app/controller.service';
import { BaseLocationService } from 'src/app/gis/location/base.location.service';
import { MapService } from 'src/app/gis/services/map.service';

@Component({
  selector: 'app-result-list-item',
  templateUrl: './result-list-item.component.html',
  styleUrls: ['./result-list-item.component.scss'],
})
export class ResultListItemComponent extends BaseResultListItemComponent {
  public hoveringActive = false;
  public locationServiceEnabled = false;
  public distanceToCurrentLocation: number = null;

  constructor(
    protected controllerService: ControllerService,
    protected locationService: BaseLocationService,
    protected mapService: MapService,
    protected readonly tagManagerService: TagManagerService
  ) {
    super(controllerService, locationService, mapService);
  }

  itemClicked(evt: Event) {
    /* eslint-disable @typescript-eslint/naming-convention */
    this.tagManagerService.click({
      event: 'select_content',
      type: 'places_poi',
      label: this.poiListItem.serviceType.id,
      text: this.poiListItem.name.toLowerCase(),
      additional_info: 'list'
    });
    /* eslint-enable @typescript-eslint/naming-convention */
    this.controllerService.showPoi(this.poiListItem);
    evt.preventDefault();
  }

  hoverChanged(poi: PoiOrAggregate) {
    if (poi && poi.id === this.poiListItem.id) {
      this.hoveringActive = true;
    } else {
      this.hoveringActive = false;
    }
  }

  mouseEnter() {
    // Remove focus from current result list item if another one is hovered
    (document.activeElement as HTMLElement).blur();
    this.controllerService.hoverPoi(this.poiListItem);
  }

  mouseLeave() {
    this.controllerService.hoverPoi(null);
  }

  focus() {
    this.controllerService.hoverPoi(this.poiListItem);
  }
}
