<div>
  
  <!-- opening hours today -->
  <div *ngIf="showOpeningHoursToday === true" class="py-4">
    <h3 class="bold font-curve-regular mb-4" translate>detail.hours.today</h3>
    <div class="row">
      <div class="col-rg-6" *ngFor="let counterType of poi?.counterStates">
        <div class="time-item">
          <h4 class="font-curve-small bold">{{counterType.counterTypeName}}</h4>

          <p class="font-curve-small bold" [class.text-confirmation]="counterType.openNow === true" [class.text-danger]="counterType.openNow === false">
            <span *ngIf="counterType.openNow === true" translate> detail.hours.open </span>
            <span *ngIf="counterType.openNow === false" translate> detail.hours.closed </span>
            <span>{{counterType.timeString}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- opening hours at a specific date -->
  <div *ngIf="showOpeningHoursAtDate === true" class="py-4">
   <h3 class="bold font-curve-regular mb-4"><span>{{openingHoursAtTitle}}</span><span *ngIf="openingHoursAtHoliday">{{openingHoursAtHoliday}}</span></h3>
   <div class="row">
     <div class="col-rg-6" *ngFor="let counter of openingHoursAtCounters">
       <div class="time-item">
         <h4 class="font-curve-small bold">{{counter.counterTypeName}}</h4>

         <p class="font-curve-small">
           <span *ngFor="let timeString of counter.timeStrings">
             {{timeString}}
           </span>
         </p>
       </div>
     </div>
   </div>
 </div>

  <!-- opening hours in the next seven days -->
  <ngb-accordion #acc="ngbAccordion" activeIds="openingHoursPanel" [destroyOnHide]="false" (panelChange)="trackPanelChange($event)">      
    <ngb-panel id="openingHoursPanel">
      <ng-template ngbPanelTitle>
        <h3 class="bold font-curve-regular mb-0">{{'detail.hours.nextdays' | translate}}</h3>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="opening-hours-table-wrapper">
          <table class="table opening-hours-table">
            <thead>
              <tr>
                <th translate>detail.hours.dateTableHeader</th>
                <th *ngFor="let counterType of poi?.openingHoursNextDays.counterTypes">{{counterType}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let day of poi?.openingHoursNextDays.nextDays | slice:0:7">
                <td>
                  <p class="m-0">{{day.date | date : 'EEEEEE, dd.MM.yy'}}</p>
                  <p class="m-0">{{day.holidayName}}</p>
                </td>
                <td *ngFor="let counter of day.counters">
                  <p class="m-0" *ngFor="let timeString of counter.timeStrings">{{timeString}}</p>
                  <p class="m-0" *ngIf="counter.timeStrings.length === 0">{{'common.closed' | translate}}</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

</div>
