<div class="search-info" [ngClass]="{'hidden': !showSearchInfo}">
  <div class="search-info-text mr-3">
    <p>{{ need | async }}{{ ' · ' + (time | async | translate) }}</p>
    <p class="mb-0" *ngIf="useCurrentLocation | async" translate>search.currentlocation</p>
    <p class="mb-0" *ngIf="(useCurrentLocation | async) === false">
      <span class="sr-only">{{ 'search.location' | translate}}: </span>
      <span>{{ query | async | translate }}</span>
    </p>
  </div>
  <button class="search-info-button btn btn-primary btn-icon" (click)="scrollToSearch()">
    <i class="pi pi-2069" aria-hidden="true"></i>
    <span class="sr-only" translate>map.backtosearch</span>
  </button>
</div>
