import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, throwError, zip } from 'rxjs';
import { catchError, last, map } from 'rxjs/operators';
import { CurrentDateTimeService } from 'src/app/common/current-date-time.service';
import { convertXmlToJson } from 'src/app/common/xml/xmlandjson';
import { ConfigurationService } from 'src/app/config/configuration.service';
import { Address } from '../model/poidetail/address';
import { CounterHoliday } from '../model/poidetail/counterholiday';
import { CounterState } from '../model/poidetail/counterstate';
import { DayOpeningHour } from '../model/poidetail/dayopeninghour';
import { DeadlinesForProduct } from '../model/poidetail/deadlinesforproduct';
import { DeadlineToday } from '../model/poidetail/deadlinetoday';
import { OpeningHoursNextDaysSingeDay } from '../model/poidetail/openinghoursnextdayssingleday';
import { OpeningHoursNextDaysSingeDayCounter } from '../model/poidetail/openinghoursnextdayssingledaycounter';
import { Period } from '../model/poidetail/period';
import { PoiDetail } from '../model/poidetail/poidetail';
import { Service } from '../model/poidetail/service';
import { ServiceGroup } from '../model/poidetail/servicegroup';
import { ServiceType } from '../model/servicetype';
import { ServiceTypesCache } from '../model/types-cache.type';
import { TypesCacheService } from './types-cache.service';
import {
  addDay,
  fromDateToXmlDateString,
  fromXmlDateStringToDate,
  fromXmlTimeStringToDisplayTimeString,
  fromXmlTimeStringToToposTime,
  getToposTimeAsDate,
  isSameDay,
  stripTimeFromDate,
  toDisplayTimeString,
} from '../util/dateutil';
import { getNextDeadline } from '../util/nextdeadlineutil';
import { mergeOpeningDays } from '../util/openingdaysmerger';
import { toShortLang } from '../util/toShortLang';

@Injectable({
  providedIn: 'root',
})
export class PoiDetailService {
  private gisApiUrl: string;

  constructor(
    private http: HttpClient,
    @Inject(LOCALE_ID) private language: string,
    private configurationService: ConfigurationService,
    private typesCacheService: TypesCacheService,
    private currentDateTimeService: CurrentDateTimeService,
    private translationService: TranslateService
  ) {
    const configuration = this.configurationService.getConfiguration();
    this.gisApiUrl = configuration.gisApiUrl;
  }

  public getPoiDetail(poiId: string): Observable<PoiDetail> {
    const translations = this.translationService.get('common.need.all');
    const typesConfig: Observable<ServiceTypesCache> = this.typesCacheService.getTypes();
    const typesDeConfig: Observable<ServiceTypesCache> = this.typesCacheService.getTypes('de');

    // use new endpoint REST description with new api URL
    const poiRaw = this.http.get(`${this.gisApiUrl}/Poi?id=${poiId}`, { responseType: 'text' });

    // Make sure translations are loaded to be able to use .instant
    return concat(
      translations,
      zip(typesConfig, typesDeConfig, poiRaw).pipe(
        map(([typesConfigCache, typesDeConfigCache, poiXml]) => {
          const poiJson = convertXmlToJson(poiXml);
          return this.convertToPoi(poiJson, typesConfigCache, typesDeConfigCache);
        }),
        catchError((err) => throwError(() => err))
      )
    ).pipe(
      catchError((err) => throwError(() => err)),
      last()
    );
  }

  convertToPoi(jsonResult: any, typesConfigCache: ServiceTypesCache, typesDeConfigCache: ServiceTypesCache): PoiDetail {
    const poiDetail = new PoiDetail();
    const poiObj = jsonResult.POI;

    // Header
    poiDetail.description = this.getLangPropertyVal(poiObj);
    poiDetail.descriptionDe = this.getLangPropertyVal(poiObj, 'de');
    poiDetail.additionalDescription = this.getLangPropertyValByArray(poiObj.AdditionalDescription);
    poiDetail.additionalDescriptionDe = this.getLangPropertyValByArray(poiObj.AdditionalDescription, 'de');
    if (poiObj.Address) {
      poiDetail.street = this.getTextProperty(poiObj.Address.Street);
      poiDetail.city = this.getTextProperty(poiObj.Address.City);
      poiDetail.zip = this.getTextProperty(poiObj.Address.Zip);
    }

    // Check if accessible by wheelchair
    poiDetail.accessibleByWheelchair = this.configurationService
      .getConfiguration()
      .accessibleByWheelchairServiceIds.some((serviceId) => this.hasService(poiObj, serviceId));

    if (poiObj.Contact) {
      poiDetail.phone = this.getTextProperty(poiObj.Contact.Phone);
      poiDetail.fax = this.getTextProperty(poiObj.Contact.Fax);
    }

    // Counters
    if (poiObj.Counter !== undefined) {
      const counters: any[] = this.getAsArray(poiObj.Counter);
      for (const counter of counters) {
        this.counter(counter, poiDetail, typesConfigCache);
      }
    }

    // Products
    if (poiObj.Product !== undefined) {
      const products: any[] = this.getAsArray(poiObj.Product);
      for (const prodcut of products) {
        this.product(prodcut, poiDetail, typesConfigCache);
      }
    }

    // Services
    this.services(poiObj, poiDetail, typesConfigCache, typesDeConfigCache);

    // Ids
    poiDetail.id = this.getAttributeVal(poiObj, 'Id');
    poiDetail.typeId = this.getAttributeVal(poiObj, 'POITypeId');

    // Addresses
    poiDetail.pickpostAddress = this.readAddress(poiObj.PickpostAddress);
    poiDetail.postlagerndAddress = this.readAddress(poiObj.PostlagerndAddress);
    poiDetail.myPost24Address = this.readAddress(poiObj.MyPost24Address);

    // Coordinates
    poiDetail.coordinates.latitude = parseFloat(this.getTextProperty(poiObj.GeoLocation.CoordinateLat));
    poiDetail.coordinates.longitude = parseFloat(this.getTextProperty(poiObj.GeoLocation.CoordinateLng));

    // Technical stuff
    poiDetail.technicalZip6 = this.getTextProperty(poiObj.Address.TechnicalPLZ6);
    poiDetail.municipalityId = parseInt(this.getTextProperty(poiObj.Address.GemeindeID) ?? '-1', 10);
    poiDetail.cantonCode = this.getTextProperty(poiObj.Address.KantonCode);
    poiDetail.countryCode = this.getTextProperty(poiObj.Address.CountryCode);

    // Closed Periods
    if (poiObj.ClosedPeriod) {
      const closedPeriods = this.getAsArray(poiObj.ClosedPeriod);

      poiDetail.closedPeriods = closedPeriods.map((period) => {
        const closedFrom = fromXmlDateStringToDate(this.getTextProperty(period.DateFrom));
        const closedTo = fromXmlDateStringToDate(this.getTextProperty(period.DateTo));
        return new Period(closedFrom, closedTo);
      });
    }

    // Hints
    if (poiObj.AccessInformation) {
      const accessInformation: string = this.getLangPropertyValByArray(poiObj.AccessInformation);
      poiDetail.hints.push(accessInformation);
    }
    if (poiObj.InfoText && poiObj.InfoText.Text) {
      const infoText = this.getLangPropertyValByArray(poiObj.InfoText.Text);
      poiDetail.hints.push(infoText);
    }

    // Post Appointment
    this.postAppointments(poiObj, poiDetail);

    // Postfinance Appointment
    this.postFinanceAppointments(poiObj, poiDetail);

    // Self-service point business client offer
    this.selfServicePointBusinessClientOffers(poiObj, poiDetail);

    // MP24 without diplsay manual
    this.mp24WithoutDisplayManuals(poiObj, poiDetail);

    // Postfachanlage offer
    this.postfachanlageOffer(poiObj, poiDetail);

    return poiDetail;
  }

  private postAppointments(poiObj: any, poiDetail: PoiDetail) {
    if (poiObj.Service) {
      const toposConfig = this.configurationService.getConfiguration();
      const services = poiObj.Service.length > 1 ? poiObj.Service : [poiObj.Service];
      const result = toposConfig.postAppointmentServiceTypeIds.filter((o1) => services.some((o2) => o1 === o2._attributes.ServiceTypeId));
      if (result.length > 0) {
        let key = 'en';
        if (['de', 'fr', 'it'].includes(this.language)) {
          key = this.language;
        }
        poiDetail.postAppointmentLink = toposConfig.postAppointmentLinks[key];
      } else {
        poiDetail.postAppointmentLink = null;
      }
    }
  }

  private postFinanceAppointments(poiObj: any, poiDetail: PoiDetail) {
    if (poiObj._attributes && poiObj._attributes.POITypeId) {
      const poiTypeid = poiObj._attributes.POITypeId;
      const toposConfig = this.configurationService.getConfiguration();
      if (toposConfig.postFinanceAppointmentPoiTypeIds.includes(poiTypeid)) {
        let key = 'en';
        if (['de', 'fr', 'it'].includes(this.language)) {
          key = this.language;
        }
        poiDetail.postFinanceAppointmentLink = toposConfig.postFinanceAppointmentLinks[key];
      } else {
        poiDetail.postFinanceAppointmentLink = null;
      }
    }
  }

  private selfServicePointBusinessClientOffers(poiObj: any, poiDetail: PoiDetail) {
    if (poiObj._attributes && poiObj._attributes.POITypeId) {
      const poiTypeid = poiObj._attributes.POITypeId;
      const toposConfig = this.configurationService.getConfiguration();
      if (toposConfig.servicePointBusinessOfferPoiTypeIds.includes(poiTypeid)) {
        let key = 'en';
        if (['de', 'fr', 'it'].includes(this.language)) {
          key = this.language;
        }
        poiDetail.servicePointBusinessOfferLink = toposConfig.servicePointBusinessOfferLinks[key];
      } else {
        poiDetail.servicePointBusinessOfferLink = null;
      }
    }
  }

  private mp24WithoutDisplayManuals(poiObj: any, poiDetail: PoiDetail) {
    if (poiObj.Service) {
      const toposConfig = this.configurationService.getConfiguration();
      const services = poiObj.Service.length > 1 ? poiObj.Service : [poiObj.Service];
      const result = toposConfig.mp24WithoutDisplayServiceIds.filter((o1) => services.some((o2) => o1 === o2._attributes.ServiceTypeId));
      if (result.length > 0) {
        let key = 'en';
        if (['de', 'fr', 'it'].includes(this.language)) {
          key = this.language;
        }
        poiDetail.mp24WithoutDisplayLink = toposConfig.mp24WithoutDisplayLinks[key];
      } else {
        poiDetail.mp24WithoutDisplayLink = null;
      }
    }
  }

  private postfachanlageOffer(poiObj: any, poiDetail: PoiDetail) {
    if (poiObj._attributes && poiObj._attributes.POITypeId) {
      const poiTypeid = poiObj._attributes.POITypeId;
      const toposConfig = this.configurationService.getConfiguration();
      if (toposConfig.postfachanlageOfferPoiTypeIds.includes(poiTypeid)) {
        let key = 'en';
        if (['de', 'fr', 'it'].includes(this.language)) {
          key = this.language;
        }
        poiDetail.postfachanlageOfferLink = toposConfig.postfachanlageOfferLinks[key];
      } else {
        poiDetail.postfachanlageOfferLink = null;
      }
    }
  }

  private services(pojObj: any, poiDetail: PoiDetail, typesConfigCache: ServiceTypesCache, typesDeConfigCache: ServiceTypesCache) {
    if (pojObj.Service) {
      let services: any[] = this.getAsArray(pojObj.Service);
      const serviceGroupHintNames = this.configurationService.getConfiguration().serviceTypeHintsGroupName;

      // the array contains only the id of the servicetype.
      // this converts this array to an array that contains the real servicetype items.
      services = services.map((s) => {
        const serviceType = this.getAttributeVal(s, 'ServiceTypeId');
        const translated = typesConfigCache.typesById[serviceType];
        const german = typesDeConfigCache.typesById[serviceType];
        return { translated, german };
      });

      const grp = services.reduce(
        (accumulator, currentvalue) => {
          // services that don't have the list === true attribute will not be part of the result in groups/services.
          if (
            currentvalue.translated.group &&
            currentvalue.translated.list === true &&
            serviceGroupHintNames.includes(currentvalue.translated.group) === false
          ) {
            const groupName = currentvalue.translated.group;
            const groupNameDe = currentvalue.german.group;
            let serviceGroup = accumulator.groups.find((serviceGrp: ServiceGroup) => serviceGrp.groupName === groupName);

            // Service group
            if (serviceGroup == null) {
              serviceGroup = new ServiceGroup();
              serviceGroup.groupName = groupName;
              serviceGroup.groupNameDe = groupNameDe;
              serviceGroup.services = [];
              serviceGroup.iconId = '';
              serviceGroup.sortOrder = currentvalue.translated.groupSortKey;

              accumulator.groups.push(serviceGroup);
            }

            // Service
            const service: Service = {
              serviceName: currentvalue.translated.desc,
              serviceNameDe: currentvalue.german.desc,
              url: currentvalue.translated.url,
              sortOrder: currentvalue.translated.itemSortKey,
            };
            serviceGroup.services.push(service);
          }
          return accumulator;
        },
        { groups: [] }
      );

      // Sort service-groups
      const groups: ServiceGroup[] = grp.groups;
      groups.sort(PoiDetailService.sortBySortOrder);

      // sort services
      groups.forEach((grpToSort) => {
        grpToSort.services.sort(PoiDetailService.sortBySortOrder);
      });

      // remove partner service groups from service groups
      // eslint-disable-next-line arrow-body-style
      const serviceGroups = groups.filter((g) => {
        return !this.configurationService.getConfiguration().partnerServiceGroups.includes(g.groupNameDe);
      });
      poiDetail.serviceGroups = serviceGroups;

      // get partner service groups from service groups
      // eslint-disable-next-line arrow-body-style
      const partnerServiceGroups = groups.filter((g) => {
        return this.configurationService.getConfiguration().partnerServiceGroups.includes(g.groupNameDe);
      });
      poiDetail.partnerServiceGroups = partnerServiceGroups;

      // service hints. Currently there is no other way than check the group name in all languages.
      const serviceHints = services.filter((x) => serviceGroupHintNames.includes(x.translated.group));
      serviceHints.sort(PoiDetailService.sortBySortOrder);

      poiDetail.serviceHints = serviceHints.map((x) => x.translated.desc);
    }
  }

  /**
   * Sort-function that sorts items by property sortOrder
   *
   * @param array The array to sort.
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static sortBySortOrder(itemA: any, itemB: any): number {
    if (itemA.sortOrder < itemB.sortOrder) {
      return -1;
    } else if (itemA.sortOrder > itemB.sortOrder) {
      return 1;
    }
    return 0;
  }

  // Addresse Pickpost und Postlagernd
  private readAddress(addressObj: any): Address {
    if (addressObj == null) {
      return;
    }
    const addr = new Address();
    addr.street = this.getLangPropertyVal(addressObj.Street);
    addr.zip = this.getTextProperty(addressObj.Zip);
    addr.city = this.getTextProperty(addressObj.City);
    addr.technicalZip = this.getTextProperty(addressObj.TechnicalPLZ6);

    return addr;
  }

  private product(productObj: any, poiDetail: PoiDetail, typesConfigCache: ServiceTypesCache) {
    const productTypeId: string = this.getAttributeVal(productObj, 'ProductTypeId');
    const productType: ServiceType = typesConfigCache.typesById[productTypeId];
    const deadlineArray: any[] = this.getAsArray(productObj.Deadline);

    // Annahmeschluss Heute
    this.deadlinesToday(deadlineArray, productType, poiDetail);

    // Annahmeschluss allgemein
    this.deadlinesInGeneral(deadlineArray, productType, poiDetail);
  }

  private deadlinesToday(deadlineArray: any[], productType: ServiceType, poiDetail: PoiDetail) {
    const now = this.currentDateTimeService.getCurrentDateTime();
    const nextDeadline: Date = getNextDeadline(deadlineArray, now);

    if (nextDeadline) {
      const deadlineToday = new DeadlineToday();
      deadlineToday.productTypeName = productType.desc;
      deadlineToday.deadLine = nextDeadline;
      poiDetail.deadlinesToday.push(deadlineToday);
    }
  }

  private deadlinesInGeneral(deadlineArray: any[], productType: ServiceType, poiDetail: PoiDetail) {
    const deadlinesProduct = new DeadlinesForProduct();
    deadlinesProduct.daysOpeningHours = [];
    deadlinesProduct.productTypeName = productType.desc;
    for (const deadline of deadlineArray) {
      if (deadline.Day._text && deadline.LatestTime._text) {
        const dayNumberString = this.getTextProperty(deadline.Day);
        const dayShortText = this.getWeekdayName(dayNumberString);
        const time = this.getTextProperty(deadline.LatestTime);
        const topostime = fromXmlTimeStringToToposTime(time);
        const timestring = toDisplayTimeString(topostime.hour, topostime.minute);
        const dayOpeningHour: DayOpeningHour = {
          dayId: Number(dayNumberString),
          daysText: dayShortText,
          timeString: timestring,
          timeStrings: [timestring],
          // eslint-disable-next-line radix
          sort: parseInt(dayNumberString),
        };
        deadlinesProduct.daysOpeningHours.push(dayOpeningHour);
      }
    }

    // Wochentage zusammen fassen( Mo, Di -> Mo - Di)
    deadlinesProduct.daysOpeningHoursSeperated = deadlinesProduct.daysOpeningHours;
    deadlinesProduct.daysOpeningHours = mergeOpeningDays(deadlinesProduct.daysOpeningHours);
    poiDetail.deadlinesForProduct.push(deadlinesProduct);
  }

  private getWeekdayName(dayAsStringNumber: string): string {
    const keys = [
      'common.week.mondayShort',
      'common.week.tuesdayShort',
      'common.week.wednesdayShort',
      'common.week.thursdayShort',
      'common.week.fridayShort',
      'common.week.saturdayShort',
      'common.week.sundayShort',
    ];
    // eslint-disable-next-line radix
    const index = parseInt(dayAsStringNumber) - 1;
    return isNaN(index) ? '' : this.translationService.instant(keys[index]);
  }

  private counter(counter: any, poiDetail: PoiDetail, typesConfigCache: ServiceTypesCache) {
    const counterType = this.getAttributeVal(counter, 'CounterTypeId');
    const counterTypeName = typesConfigCache.typesById[counterType].desc;

    // Openinghours for the next 7 days
    this.openingHoursNextDays(counter, poiDetail, counterTypeName);

    // Openinghours today
    const counterState = this.createCounterState(counter, counterTypeName);
    if (counterState) {
      poiDetail.counterStates.push(counterState);
    }
  }

  private openingHoursNextDays(counter: any, poiDetail: PoiDetail, counterTypeName: string) {
    if (!counter.CalculatedOpeningHours) {
      return;
    }

    const calculatedOpeningHours = this.getAsArray(counter.CalculatedOpeningHours);
    if (calculatedOpeningHours.length > 0) {
      poiDetail.openingHoursNextDays.counterTypes.push(counterTypeName);
    }

    let currentDay = this.currentDateTimeService.getCurrentDateTime();
    currentDay = stripTimeFromDate(currentDay);
    const numDaysForOpenAt = this.configurationService.getConfiguration().numDaysEnabledForOpenAt;
    for (let i = 0; i < numDaysForOpenAt; i++) {
      const currentDateString = fromDateToXmlDateString(currentDay);

      let singleDay: OpeningHoursNextDaysSingeDay = poiDetail.openingHoursNextDays.nextDays.find((x) => isSameDay(x.date, currentDay));
      if (!singleDay) {
        singleDay = {
          holidayName: null,
          counters: [],
          date: currentDay,
        };
        poiDetail.openingHoursNextDays.nextDays.push(singleDay);
      }

      // Check holiday
      if (!singleDay.holidayName) {
        const holidays: any[] = this.getAsArray(counter.Holiday);
        const holiday = holidays.find((x) => this.getTextProperty(x.Date) === currentDateString);
        if (holiday) {
          singleDay.holidayName = this.getLangPropertyValByArray(holiday.Name);
        }
      }

      const openingHoursNextDaysSingeDayCounter: OpeningHoursNextDaysSingeDayCounter = {
        timeStrings: [],
      };
      singleDay.counters.push(openingHoursNextDaysSingeDayCounter);

      const item = calculatedOpeningHours.find((x) => this.getTextProperty(x.Date) === currentDateString);
      if (item) {
        const timescliceArray = this.getAsArray(item.TimeSlice);
        for (const timeslice of timescliceArray) {
          let timeFrom: string = this.getTextProperty(timeslice.TimeFrom);
          let timeTo = this.getTextProperty(timeslice.TimeUntil);
          timeFrom = fromXmlTimeStringToDisplayTimeString(timeFrom);
          timeTo = fromXmlTimeStringToDisplayTimeString(timeTo);
          const displayTimeString = `${timeFrom} - ${timeTo}`;
          openingHoursNextDaysSingeDayCounter.timeStrings.push(displayTimeString);
        }
      }

      currentDay = addDay(currentDay);
    }
  }

  private createCounterState(counter: any, name: string): CounterState {
    const res = new CounterState();
    res.counterTypeName = name;

    if (counter.Holiday) {
      const holidays: any[] = this.getAsArray(counter.Holiday);
      res.holidays = holidays.map(
        (holiday) => new CounterHoliday(fromXmlDateStringToDate(this.getTextProperty(holiday.Date)), !!holiday.TimeSlice)
      );
    }

    // there are no opening hours, so there is no counter state
    if (!counter.CalculatedOpeningHours || !Array.isArray(counter.CalculatedOpeningHours)) {
      return null;
    }

    const calculatedOpeningHours: any[] = counter.CalculatedOpeningHours;
    this.calculateOpenClosed(calculatedOpeningHours, res);

    return res;
  }

  private calculateOpenClosed(calculatedOpeningHours: any[], counterState: CounterState) {
    const now = this.currentDateTimeService.getCurrentDateTime();
    const nowString = fromDateToXmlDateString(now);
    const calculatedOpeningHour = calculatedOpeningHours.find((x) => {
      const dateString = this.getTextProperty(x.Date);
      return dateString === nowString;
    });

    let timeSlices = [];
    if (calculatedOpeningHour) {
      timeSlices = this.getAsArray(calculatedOpeningHour.TimeSlice);
    }

    let entryFound = false;

    for (const timeslice of timeSlices) {
      const timeFrom: string = this.getTextProperty(timeslice.TimeFrom);
      const timeUntil: string = this.getTextProperty(timeslice.TimeUntil);

      const toposTimeFrom = fromXmlTimeStringToToposTime(timeFrom);
      const toposTimeUntil = fromXmlTimeStringToToposTime(timeUntil);

      const timeFromAsDate = getToposTimeAsDate(now, toposTimeFrom);
      const timeToAsDate = getToposTimeAsDate(now, toposTimeUntil);

      // still not open
      if (now.getTime() < timeFromAsDate.getTime()) {
        counterState.openUntil = null;
        counterState.openAgain = timeFromAsDate;
        entryFound = true;
        break;
      }

      // open
      if (now.getTime() <= timeToAsDate.getTime() && now.getTime() >= timeFromAsDate.getTime()) {
        counterState.openUntil = timeToAsDate;
        calculatedOpeningHours.splice(0, 1);
        counterState.openAgain = this.getNextOpeningEvent(calculatedOpeningHours);
        entryFound = true;
        break;
      }
    }

    // closed already
    if (entryFound === false) {
      // only remove first element if there are opening hours for today (closed already)
      // but not if there are no opening hours for today (closed all day)
      if (calculatedOpeningHour) {
        calculatedOpeningHours.splice(0, 1);
      }
      counterState.openAgain = this.getNextOpeningEvent(calculatedOpeningHours);
    }
  }

  private getNextOpeningEvent(calculatedOpeningHours: any[]): Date {
    const now = this.currentDateTimeService.getCurrentDateTime();
    for (const calculatedOpeningHour of calculatedOpeningHours) {
      const dateString = this.getTextProperty(calculatedOpeningHour.Date);
      const date = fromXmlDateStringToDate(dateString);

      // we are looking for the next date that is in the future.
      if (date < now) {
        continue;
      }

      const timesclices = this.getAsArray(calculatedOpeningHour.TimeSlice);
      if (timesclices && timesclices.length > 0) {
        const dateFromXml = this.getTextProperty(calculatedOpeningHour.Date);
        const event: Date = fromXmlDateStringToDate(dateFromXml);
        const timeFrom: string = this.getTextProperty(timesclices[0].TimeFrom);
        const toposTimeFrom = fromXmlTimeStringToToposTime(timeFrom);
        return getToposTimeAsDate(event, toposTimeFrom);
      }
    }
    return null;
  }

  private getAsArray(obj: any): any[] {
    if (!obj) {
      return [];
    }
    if (Array.isArray(obj)) {
      return obj;
    } else {
      return [obj];
    }
  }

  private hasService(obj: any, serviceTypeId: string) {
    const services = this.getAsArray(obj.Service);
    return services.some((x) => x._attributes !== undefined && x._attributes.ServiceTypeId === serviceTypeId);
  }

  private getAttributeVal(obj: any, attributeName: string): string {
    if (obj === undefined || obj._attributes === undefined) {
      return null;
    }
    return obj._attributes[attributeName];
  }

  private getTextProperty(obj: any): any {
    if (obj === undefined) {
      return null;
    }
    if (obj._text === undefined) {
      return null;
    }
    return obj._text;
  }

  private getLangPropertyVal(descriptionObjs: any, language?: string) {
    if (descriptionObjs == null || descriptionObjs.Description == null) {
      return null;
    }
    const property: any[] = descriptionObjs.Description;
    return this.getLangPropertyValByArray(property, language);
  }

  private getLangPropertyValByArray(property: any[], language?: string): string {
    if (!property) {
      return null;
    }

    const lang = language ? language : toShortLang(this.language);
    if (property && property.length > 0) {
      const x = property.filter((y) => y._attributes.lang === lang);
      if (x.length > 0) {
        return x[0]._text;
      }
    }
    return '';
  }
}
