import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription, zip } from 'rxjs';

import { SearchParameters } from 'src/app/gis/model/searchparameters';
import { ServiceType } from 'src/app/gis/model/servicetype';
import { NeedsCacheService } from 'src/app/gis/services/needs-cache.service';
import { TypesCacheService } from 'src/app/gis/services/types-cache.service';
import { ControllerService } from 'src/app/controller.service';
import { TranslateExtendedService } from 'src/app/common/translate-extended.service';

@Component({
  selector: 'app-result-filter',
  templateUrl: './result-filter.component.html',
  styleUrls: ['./result-filter.component.scss'],
})
export class ResultFilterComponent implements OnInit, OnDestroy {
  public poiTypes: ServiceType[];
  public hasResults: boolean;
  public resultCount: number;
  public srText: string;

  private search$: Subscription;
  private searchParameters$: Subscription;

  constructor(
    private controllerService: ControllerService,
    private typesCache: TypesCacheService,
    private needsCache: NeedsCacheService,
    private translateExtendedService: TranslateExtendedService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.search$ = this.controllerService.getSearchObservable().subscribe(async (poiList) => {
      // Workaround for screenreader which wouldn't detect changes fast enough,
      // if the amount of results dont change with a second search.
      setTimeout(() => {
        if (poiList.pois.length === 0) {
          this.srText = this.translateExtendedService.instant('resultfilter.poicountenhance', { num: poiList.count });
        } else {
          this.srText = this.translateExtendedService.instant('resultfilter.poicount', { num: poiList.count });
        }
      }, 300);

      this.resultCount = poiList.count;
      this.hasResults = this.resultCount > 0;
    });

    this.searchParameters$ = this.controllerService.getSearchParameters().subscribe((searchParameters) => {
      // waiting
      this.srText = this.translateExtendedService.instant('resultfilter.searching');

      // Reset filter dropdown if the search form is reset
      // Also, initially fill the (hidden) filter with all possible Poi types in case a user searches for everything
      if (searchParameters) {
        this.filterOptionsByNeed(searchParameters);
      }
    });
  }

  ngOnDestroy() {
    if (this.search$) {
      this.search$.unsubscribe();
    }
    if (this.searchParameters$) {
      this.searchParameters$.unsubscribe();
    }
  }

  /**
   * Trigger a new search with the same parameters as before, but set a service type filter.
   *
   * @param serviceTypeTag ESRI Poi tag
   */
  public filterChanged(serviceTypeTag: string) {
    const serviceTypeFilter = serviceTypeTag === '' ? null : [serviceTypeTag];

    const newParameters: SearchParameters = {
      ...this.controllerService.getSearchParameters().value,
      serviceTypeFilter,
      resetFilter: false,
      viewportWidth: this.document.body.clientWidth,
      scrollToMap: true,
    };

    if (this.controllerService.isInitialSearchDone()) {
      this.controllerService.startSearch(newParameters);
    }
  }

  /**
   * The user changed their need, update available poi types
   *
   * @param searchParameters Search parameters from needs.json
   */
  private filterOptionsByNeed(searchParameters: SearchParameters) {
    zip(this.needsCache.getNeedById(searchParameters.needId), this.typesCache.getTypes()).subscribe(([need, types]) => {
      this.poiTypes = need.pois.map((tag) => types.typesByTag[tag]).filter((x) => /*filter poi types that don't exist anymore*/ x);

      // if the search is triggered by the search button, we reset the filter
      if (searchParameters.resetFilter) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const dropdown = <HTMLSelectElement>document.getElementById('result-filter-facility-type');
        dropdown.value = '';
      }
    });
  }
}
