<header class="result-filter-header container hidden-md-up">
  <h2 class="font-curve-bigger-regular bold" translate>resultfilter.title</h2>
  <hr>
</header>

<div class="result-filter container">
  <div class="result-filter-item result-filter-info hidden-md-down">   
    <p class="text-gray-600" *ngIf="hasResults">{{ 'resultfilter.poicount' | translate : { num: resultCount } }}</p>        
  </div>

  <!-- Only for screenreaders -->
  <span id="sr-only-text" class="sr-only" aria-live="polite">{{ srText }}</span>
  
  <div class="result-poitype-filter result-filter-item">
    <label for="result-filter-facility-type" class="visuallyhidden" translate>resultfilter.poitype</label>
    <select
      id="result-filter-facility-type"
      class="form-control custom-select"
      (change)="filterChanged($event.target.value)"
    >
      <option value="" translate>resultfilter.all</option>
      <option *ngFor="let type of poiTypes" [value]="type.id">{{type.desc}}</option>
    </select>
  </div>
</div>
