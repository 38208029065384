<div class="result-list-wrapper" #resultlist>
  <a
    [router-link]=""
    class="result-count-mobile result-list font-curve-regular light"
    *ngIf="resultCount | async"
    (click)="scrollResultList()"
  >
    <i class="pi pi-2052" aria-hidden="true"></i> {{'resultfilter.poicount'| translate : {num: (resultCount | async ) } }}
  </a>

  <div class="result-info" *ngIf="showPleaseZoom">
    <svg class="result-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
      <path fill="#010202" d="M32,28.2l-11-11c1.1-1.7,1.7-3.7,1.7-5.9C22.7,5.1,17.6,0,11.3,0S0,5.1,0,11.3c0,6.3,5.1,11.3,11.3,11.3
	c2.2,0,4.2-0.6,5.9-1.7l11,11L32,28.2z M1.3,11.3c0-5.5,4.5-10,10-10s10,4.5,10,10s-4.5,10-10,10S1.3,16.8,1.3,11.3z M18.5,20.1
	c0.1,0,0.1-0.1,0.2-0.1c0.6-0.5,1.1-1,1.6-1.6l9.8,9.8l-1.9,1.9l-9.8-9.8C18.4,20.2,18.5,20.1,18.5,20.1z M12,10.7h5.3V12H12v5.3
	h-1.3V12H5.3v-1.3h5.3V5.3H12V10.7z" />
    </svg>
    <p class="font-curve-regular" translate>resultlist.pleasezoom</p>
  </div>

  <div class="result-info" *ngIf="showNoResults">
    <i class="pi pi-1034 result-icon" aria-hidden="true"></i>
    <p class="font-curve-regular" translate>resultlist.nopoisfound</p>
    <button class="btn btn-primary btn-animated no-results-button" type="button" (click)="editSearch()" translate>resultlist.editsearch</button>
  </div>

  <div class="result-info" *ngIf="noPoisOnMap">
    <i class="pi pi-3137 result-icon" aria-hidden="true"></i>
    <p class="font-curve-regular" translate>resultlist.nopoisonmap</p>
    <button class="btn btn-primary btn-animated no-poi-button" type="button" (click)="expandSearchClick()" translate>resultlist.expandsearch</button>
  </div>

  <ol #resultCountHeader class="result-list" *ngIf="resultCount | async">
    <li *ngFor="let item of poiList.flattenedPois">
      <app-result-list-item [poiListItem]="item"></app-result-list-item>
    </li>
  </ol>

  <button class="back-to-top btn btn-icon btn-default" (click)="scrollToSearch()" [placement]="tooltipPlacement()" [disableTooltip]="disableTooltip()" [ngbTooltip]="'map.searchinfo.backtosearch' | translate">
    <i class="pi pi-3026" aria-hidden="true"></i>
    <span class="sr-only" translate>map.searchinfo.backtosearch</span>
  </button>
</div>